<template>
  <authentic-modal
    ref="userProfileModal"
    id="user-profile-modal"
    :on-submit="submit"
    size="lg"
    :title="title"
  >
    <b-form ref="user-profile-form" class="p-4">
      <b-form-row class="pb-4">
        <b-col md="5" lg="3">
          <AvatarSelector />
        </b-col>

        <b-col flex>
          <b-row no-gutters>
            <b-link @click="forgotPasswordLink" class="reset"
              >Reset your password?</b-link
            >
          </b-row>
          <b-row></b-row>
          <b-row no-gutters style="display: block">
            <b-form-group
              id="name-input"
              class="labels labels-names my-0"
              label-for="name"
              content-cols="12"
            >
            <template v-slot:label>
              <span class="text-danger">*</span>Name
            </template>
              <b-form-input
                v-model="(name.value.value as string)"
                id="name"
                class="form-input"
                placeholder="Enter your name..."
                required
                :state="name.errorMessage.value ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback id="email-live-feedback">{{
                name.errorMessage.value
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-row>
        </b-col>
      </b-form-row>
      <b-row>
        <b-col><h5>Contact Information</h5></b-col>
      </b-row>
      <b-form-row>
        <b-col sm="8">
          <b-form-group
            id="email-input"
            class="labels"
            label-for="email"
            content-cols="12"
          >
            <template v-slot:label>
              <span class="text-danger">*</span>Email
            </template>
            <b-form-input
              id="email"
              class="form-input"
              v-model="(userEmail.value.value as string)"
              placeholder="Enter your email..."
              required
              :state="userEmail.errorMessage.value ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback id="email-live-feedback">{{
              userEmail.errorMessage.value
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="phone-number"
            class="labels"
            label-for="phonenumber"
            content-cols="12"
          >
            <template v-slot:label>
              <span class="text-danger">*</span>Phone
            </template>

            <b-form-input
              id="phonenumber"
              class="form-input"
              v-model="(userPhone.value.value as string)"
              placeholder="xxx-xxx-xxxx"
              :state="userPhone.errorMessage.value ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback id="email-live-feedback">{{
              userPhone.errorMessage.value
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
    <template v-slot:modal-footer>
      <b-button-group class="pr-4">
        <b-button
          pill
          variant="outline-secondary"
          size="sm"
          class="ml-2"
          @click.prevent="cancel"
          >Cancel</b-button
        >
        <b-button
          pill
          variant="primary"
          size="sm"
          class="ml-2"
          :disabled="!meta.valid"
          @click.prevent="submit"
          >Save</b-button
        >
      </b-button-group>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
import AvatarSelector from "../shared/AvatarSelector.vue";
import User from "../../models/User";
import { profile } from "../../services/UserService";
import AuthenticModal from "../shared/AuthenticModal.vue";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { ref as yupRef } from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, inject, markRaw, onBeforeMount, onMounted, ref } from "vue";
import { useToast } from "vue-toastification";

const emitter: any = inject("emitter");
const $store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const userProfileModal = ref<AuthenticModal>();

const user = computed(() => {
  return $store.getters.authUser;
});

const validationSchema = markRaw(
  object({
    name: string().required("name is a required field"),
    userPhone: string().required("user phone is required"),
    userEmail: string()
      .email("please provide a valid email")
      .required("user email is required")
      .trim(),
  })
);
const { validate, errors, meta } = useForm({ validationSchema });
const name = useField("name", validationSchema);
const userPhone = useField("userPhone", validationSchema);
const userEmail = useField("userEmail", validationSchema);

const title = "User Profile";

onMounted(() => {
  emitter.on("user-profile", () => {
    name.value.value = user.value.name;
    userEmail.value.value = user.value.email;
    userPhone.value.value = user.value.phone;
    userProfileModal.value?.show();
  });
});

function cancel() {
  userProfileModal.value?.hide();
}

function forgotPasswordLink() {
  userProfileModal.value?.hide();
  router.replace({ name: "forgotPassword" });
}

async function submit() {
  const isValid = await validate();
  if (!isValid.valid) {
    return;
  }
  try {
    user.value.name = name.value.value as string;
    user.value.email = userEmail.value.value as string;
    user.value.phone = userPhone.value.value as string;
    await profile(user.value.id!, user.value);
    $store.commit("authUser", user.value);
    userProfileModal.value?.hide();
    toast.success("User profile updated.");
  } catch (error) {
    toast.error("An error occurred when updating your user profile!");
  }
}
</script>

<style scoped>
.profile-avatar {
  border: 0;
  border-radius: 10px;
  width: 150px;
}
.labels-names {
  font-size: 16px !important;
}

.form-input {
  border-radius: 8px;
}
.labels {
  font-size: 18px;
}
:deep .btn:focus,
.btn.focus {
  box-shadow: none;
}
.footer-button {
  height: 30px;
  font-size: 16px;
  line-height: 14px;
}
.reset {
  color: red;
}
.modal {
  top: 100px;
}
</style>
