/**
 * Setting up axios client for API calls
 */

import axios from "axios";
import { store } from "../store/index";
import router from "../router/index";
import { logout } from "../services/AuthService";

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  withCredentials: true,
});

/**
 * Axios response interceptor
 */
let isRefreshing = false;
apiClient.interceptors.response.use(
  (response) => {
    // TODO this is probably the best place to get the csrf token, 
    // TODO however it takes about 2 times as long to get the response
    // TODO back. It is faster if I place it in each of the AuthService functions. 
    // Refresh CSRF token after each successful response if needed
    // if (response.config.url !== 'sanctum/csrf-cookie' && !isRefreshing) {
    //   isRefreshing = true;
    //   return apiClient.get('sanctum/csrf-cookie').then(() => {
    //     isRefreshing = false;
    //     return response;
    //   });
    // }
    return response;
  },
  (error) => {
    // Check session time if error and time exceeded just force a relog
    const sessionDuration = import.meta.env.VITE_APP_SESSION_LIFETIME;
    // console.log("sessionDuration", sessionDuration)
    console.log(error, "error")
    // const sessionDuration = 120;
    const sessionStart = store.getters.sessionStart;
    const timeDiff = (Date.now() - sessionStart) / 60000; //check in minutes
    let isSessionExpired = false;
    if (timeDiff > sessionDuration) {
      isSessionExpired = true;
    }
    const status = error.response.status;
    if (status === 401 || status === 419 || isSessionExpired) {
      logout();
      store.commit("login", false);
      router.push({ name: "login" });
    } else {
      return Promise.reject(error);
    }
  }
);
