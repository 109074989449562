<template>
  <authentic-modal
    ref="inviteUserModal"
    id="invite-user-modal"
    :title="modalTitle"
    :on-submit="submit"
    size="md"
  >
    <b-form ref="form" class="pl-4">
      <b-form-group
        label-cols="12"
        content-cols="12"
        label="Email"
        label-for="user-email"
        class="mb-0 pr-4"
      >
        <b-form-input
          id="user-email"
          v-model="(newUserEmail.value.value as string)"
          aria-describedby="company-name-live-feedback"
          :state="newUserEmail.errorMessage.value ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback id="company-name-live-feedback">{{
          newUserEmail.errorMessage.value
            }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label-cols="12"
        content-cols="7"
        label="Role"
        label-for="user-role"
        class="mb-0"
      >
        <b-form-select
          id="user-role"
          v-model="selectedRole"
          :options="roleOptions"
          aria-placeholder="Select role..."
        ></b-form-select>
      </b-form-group>
    </b-form>

    <template v-slot:modal-footer>
      <b-button-group class="pr-4">
        <b-button
          pill
          variant="outline-secondary"
          size="sm"
          class="ml-2"
          @click.prevent="cancel"
          >Cancel</b-button
        >
        <b-button
          pill
          variant="primary"
          size="sm"
          class="ml-2"
          :disabled="!meta.valid"
          @click.prevent="submit"
          >Send</b-button
        >
      </b-button-group>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
import AuthenticModal from "../shared/AuthenticModal.vue";
import { Company } from "@authentic-labs/vue-component-library";
import User from "../../models/User";
import { createUser } from "../../services/AdminService";
import { useToast } from "vue-toastification";

import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { ref as yupRef } from "yup";
import { computed, inject, markRaw, onMounted, ref } from "vue";
import { useStore } from "vuex";

const emitter: any = inject("emitter");
const $store = useStore();
const toast = useToast();

const inviteUserModal = ref<AuthenticModal>();

const user = computed(() => {
  return $store.getters.authUser;
});
const company = computed(() => {
  return $store.getters.company;
});

const validationSchema = markRaw(
  object({
    newUserEmail: string()
      .email("please provide a valid email")
      .required("user email is required")
      .trim(),
  })
);
const { validate, errors, setErrors, meta } = useForm({ validationSchema });
const newUserEmail = useField("newUserEmail", validationSchema);

let newUser: Partial<User> = {};
const modalTitle = "Invite user";

let selectedRole = "User";
const roleOptions = [
  { value: "User", text: "User" },
  { value: "Admin", text: "Admin" },
];

onMounted(async () => {
  emitter.on("invite-user", () => {
    inviteUserModal.value?.show();
  });
});

async function submit() {
  newUser.role = selectedRole;
  newUser.status = "Active";
  newUser.email = newUserEmail.value.value as string;
  try {
    const response = await createUser(company.value.companyId, newUser);
    if (response === "success") {
      inviteUserModal.value?.hide();
      toast.success(newUser.email + " has been invited to create an account!");
      newUser = {};
      emitter.emit("newuserinvite");
    } else {
      toast.error("User invite failed, please try again");
    }
  } catch {
    toast.error("User invite failed, please try again");
  }
}
async function cancel() {
  inviteUserModal.value?.hide();
}

function beforeDestroy() {
  emitter.off("invite-user");
}
</script>

<style scoped></style>
