<template>
  <b-container class="container-fluid d-flex h-100 flex-column">
    <b-row no-gutters>
      <b-col>
        <h5 class="current">Current plan</h5>
      </b-col></b-row
    >
    <b-row class="align-items-center">
      <b-col>
        <b-row no-gutters
          ><b-card-text class="subscription-plan">{{
            billingDetails.subscriptionName
          }}</b-card-text></b-row
        >
        <b-row class="pb-0" no-gutters>
          <b-card-text class="subscription-price"
            >${{ billingDetails.planPrice }} /
            {{ billingDetails.paymentInterval }}</b-card-text
          >
        </b-row>
        <b-row no-gutters>
          <b-card-text class="subscription-renewal"
            >Number of included products available on your current plan:
            {{ billingDetails.productNumber }}</b-card-text
          >
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        ><b-card-text
          >You can add additional products to your current plan for $20 / month
        </b-card-text></b-col
      >
    </b-row>

    <b-row class="pt-3 current">
      <b-col>
        <label for="demo-sb"
          >Additional products (shows current number of added products).</label
        >
        <b-form-spinbutton 
          id="demo-sb"
          v-model="productNumber"
          min="0"
          max="100"
          style="width: 8rem"
          @change="updateProductNumbers($event)"
        ></b-form-spinbutton>
      </b-col>
    </b-row>

    <b-row class="pt-3">
      <b-col class="pr-0 col-7">
        <b-card-text class="current-payment" v-if="showUpdatedPricing"
          >What you will pay {{ freq }} starting
          {{ billingDetails.renewalDate }}</b-card-text
        >
        <b-card-text class="current-payment" v-else
          >What you will pay {{ freq }}</b-card-text
        >
      </b-col>
      <b-col>
        <b-row>
          <b-col class="pr-2 text-right col-3">${{ updatedPrice }}</b-col>
        </b-row>

        <b-row>
          <b-col class="col-6 text-muted default-payment">
            <span class="pr-1">{{
              $filters.capitalizeFirst(defaultPayment.brand)
            }}</span>
            <span class="pr-0"
              ><span>&bull;&bull;&bull;&bull;</span>
              {{ defaultPayment.last_four }}</span
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="flex-grow-1 align-items-end text-right row-bottom pb-3">
      <b-col class="align-right">
        <b-button-group>
          <b-button
            pill
            variant="outline-secondary"
            size="sm"
            v-b-tooltip.hover
            title="Tooltip directive content"
            @click="summary"
            :disabled="disableSubmit"
            >Go back</b-button
          >
          <!-- @vue-ignore -->
          <b-button
            ref="updateQuantityButton"
            pill
            :variant="confirmButton"
            size="sm"
            class="mx-2"
            @click="confirmQuantityUpdate"
            :disabled="disableSubmit"
            >Confirm changes</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
import Billing from "../../models/Billing";
import { updateAdditionalProducts } from "@/services/PaymentService";
import { createProduct } from "../../services/ProductService";
import { BButton } from "bootstrap-vue";
import { uploadFile } from "../../services/FileService";
import { Product } from "@authentic-labs/vue-component-library";
import { useToast } from "vue-toastification";
@Component({})
export default class BillingProductQuantity extends Vue {
  @Prop()
  billingDetails!: Billing;

  @Prop()
  unarchive!: boolean;

  @Ref()
  updateQuantityButton!: BButton;

  get products() {
    return this.$store.getters.products;
  }

  get user() {
    return this.$store.getters.authUser;
  }

  get freq(): string {
    if (this.billingDetails.paymentInterval === "year") {
      return "yearly";
    }
    return "monthly";
  }

  get updatedPrice(): string {
    return this.updatedPricing;
  }

  get defaultPayment() {
    let paymentMethod: any = [];
    const found = this.billingDetails.paymentMethods.find((element: any) => {
      element.default === true;
      paymentMethod = element;
    });
    return paymentMethod;
  }
  get placeHolderHeaderImage(): string {
    return new URL("/src/assets/images/app/placeholder16x9.png", import.meta.url).href;
  }
  get placeHolderLogoImage(): string {
    return new URL("/src/assets/images/app/placeholder1x1.png", import.meta.url).href;
  }

  confirmedButtonHTML = `<div class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                  </svg> <span class="confirmed-text-span">Changes confirmed</span>
                </div>`;

  showUpdatedPricing = false;
  summary() {
    this.emitter.emit("billing-summary");
  }

  toast = useToast();

  //TODO need to create a subscription or currentofferings table to store these params (much easier to update!)
  perProductCost = 20;
  productNumber = 0;
  updatedPricing = "";
  disableSubmit = false;
  confirmButton = "primary";
  priceId = "";
  productQuantity = 0;
  payLoad: Partial<Product> = {};

  mounted() {
    this.productNumber = this.billingDetails.additionalProducts;
    this.updatedPricing = this.billingDetails.planPrice;
    // if (this.billingDetails.paymentInterval === "month") {
    //   this.perProductCost = 20;
    // } else {
    //   this.perProductCost = 240;
    // }
  }

  setPriceId() {
    // this.billingDetails.paymentInterval === "year"
    //   ? (this.priceId = import.meta.env.VITE_APP_PRO_PRODUCT_YEARLY)
    //   : (this.priceId = import.meta.env.VITE_APP_PRO_PRODUCT_MONTHLY);

    this.billingDetails.paymentInterval === "year"
      ? (this.priceId = import.meta.env.VITE_APP_PRO_PRODUCT_YEARLY)
      : (this.priceId = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT);
  }

  updateProductNumbers(value: number) {
    if (this.billingDetails.additionalProducts !== value) {
      this.updatedPricing = (
        parseInt(this.billingDetails.planPrice) +
        (value - this.billingDetails.additionalProducts) * this.perProductCost
      ).toString();
      this.productQuantity = value - this.billingDetails.additionalProducts;
      this.showUpdatedPricing = true;
    } else {
      this.showUpdatedPricing = false;
      this.updatedPricing = this.billingDetails.planPrice;
    }
  }

  async confirmQuantityUpdate() {
    this.setPriceId();
    let subscribeResponse = "";
    this.disableSubmit = true;
    if (this.billingDetails.paymentMethods) {
      const paymentDetails = {
        paymentMethod: this.defaultPayment.id,
        priceId: this.priceId,
        quantity: this.productQuantity,
        subscriptionType: this.billingDetails.subscriptionName,
        currentAdditionalProducts: this.billingDetails.additionalProducts,
      };
      subscribeResponse = await updateAdditionalProducts(paymentDetails);
      this.$store.dispatch("getSubscriptionStatus", { user: this.user });
      if (subscribeResponse === "failed") {
        this.paymentResponse(subscribeResponse);
        this.productNumber = this.billingDetails.additionalProducts;
        this.disableSubmit = false;
        return;
      } else {
        if (this.unarchive) {
          this.emitter.emit("billing-summary", "refresh");
          this.emitter.emit("billing-modal-close");
          this.emitter.emit("from-billing-unarchive");
        } else {
          this.createProduct(this.productQuantity);
          this.updateQuantityButton.innerHTML = this.confirmedButtonHTML;
          this.confirmButton = "success";
          this.emitter.emit("billing-summary", "refresh");
        }
      }
    }
    this.paymentResponse(subscribeResponse);
  }

  async setPlaceholderImages() {
    const headerResponse = await fetch(this.placeHolderHeaderImage);
    const logoResponse = await fetch(this.placeHolderLogoImage);
    const headerBlob = await headerResponse.blob();
    const logoBlob = await logoResponse.blob();
    const headerFile = new File([headerBlob], "placeholder16x9.png", {
      type: headerBlob.type,
    });
    const logoFile = new File([logoBlob], "placeholder1x1.png", {
      type: logoBlob.type,
    });
    const fileHeader = await uploadFile(headerFile);
    const fileLogo = await uploadFile(logoFile);
    return { fileHeaderId: fileHeader.filesId, fileLogoId: fileLogo.filesId };
  }

  async createProduct(quantity: number) {
    this.payLoad = {
      model: "",
      name: "Product name",
      status: "active",
    };
    const placeholderResponse = await this.setPlaceholderImages();
    this.payLoad.imageFile = { filesId: placeholderResponse.fileHeaderId };
    this.payLoad.appLogoFile = { filesId: placeholderResponse.fileLogoId };
    try {
      for (let i = 0; i < quantity; i++) {
        const products = await createProduct(this.user.companyId, this.payLoad);
        this.toast.success("New Product was successfully added.");
        this.emitter.emit("newProductAdded", products);
      }
    } catch (error) {
      this.toast.error("Something went wrong. Product was not added.");
      console.error("Error adding new product: " + error);
    }
  }

  paymentResponse(status: string) {
    if (status === "success") {
      this.toast.success("The number of products has been updated for your subscription");
      //this.emitter.emit("subscription-modal");
    } else {
      this.toast.error("There was an issue with update number of products");
    }
  }
}
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
  /* padding-left: 2rem; */
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}
.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-payment {
  font-size: 18px;
}
.default-payment {
  font-size: 12px;
}

.btn.btn-success {
  transition-duration: 0.75s;
  animation-name: colorTransition;
}

@keyframes colorTransition {
  from {
    background-color: #3679e8 !important;
  }
  to {
    background-color: #38c172 !important;
  }
}
</style>
