<template>
  <b-container>
    <!-- @vue-ignore -->
    <b-card border-variant="0">
      <b-row class="pb-2">
        <b-col>
          <b-button
            pill
            size="sm"
            variant="outline-primary"
            class="new-user"
            @click="inviteUser"
            >{{ actionTitle }}</b-button
          >
        </b-col>
      </b-row>

      <b-row v-if="hasSearch">
        <!-- TODO third use of a search bar and radio buttons for sorting this needs to be a component! -->
        <slot name="user-search">
          <b-input-group class="px-0">
            <b-input-group-prepend>
              <b-button
                class="search-button"
                @click="
                  page = 1;
                  getUsers();
                "
                ><icon icon="search" flip-h variant="#898989"></icon
              ></b-button>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              v-model="search"
              class="search"
              placeholder="Search by user name..."
              @keydown.enter="
                page = 1;
                getUsers();
              "
              @input="resetSearch()"
            ></b-form-input>
          </b-input-group>

          <!-- <b-form-group class="text-center"> -->
            <b-form-group v-slot="{ ariaDescribedby }" class="text-center">
            <b-form-radio-group
              class="pl-3"
              id="user-list"
              v-model="userSortSelected"
              :aria-describedby="ariaDescribedby"
              name="user-list"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                value="alpha"
                @change="sortArray"
                >Alphabetic</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                value="recent"
                @change="sortArray"
                >Recently</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </slot>
      </b-row>

      <b-row>
        <slot name="user-sort"></slot>
      </b-row>

      <b-row class="company-table py-3 px-0">
        <!-- @vue-ignore -->
        <b-table
          ref="companyusers"
          id="companyusers"
          class="company-table-height"
          sticky-header
          hover
          small
          no-border-collapse
          responsive
          primary-key="id"
          :fields="fields"
          :items="users"
          :per-page="perPage"
          @row-clicked="rowClicked"
          :key="updateTable"
        >
          <template v-slot:cell(name)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              v-model="(data.item.name as string)"
              class="edit-text limit-column-width"
            ></b-form-input>
            <span v-else>{{ data.item.name }}</span>
          </template>

          <template v-slot:cell(email)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              v-model="(data.item.email as string)"
              class="edit-text"
            ></b-form-input>
            <span v-else>{{ data.item.email }}</span>
          </template>

          <template v-slot:cell(role)="data">
            <!-- <b-form-select
              v-if="selectedRow[data.index]"
              v-model="data.item.role"
              :options="roles"
              size="sm"
            >
            </b-form-select> -->
            <!-- <div>{{ parseInt(Object.keys(selectedRow)[0]) }} {{ data.index }}</div>
            <div>{{ selectedRow[data.index] }}</div> -->
            <b-form-select
              v-if="selectedRow[data.index]"
              v-model="(data.item.role as string)"
              size="sm"
            >
              <b-form-select-option
                value="Superadmin"
                v-if="user.companyId === 1 && user.role === 'Superadmin'"
                >Superadmin</b-form-select-option
              >
              <b-form-select-option value="Admin">Admin</b-form-select-option>
              <b-form-select-option value="User">User</b-form-select-option>
            </b-form-select>
            <span v-else>{{ data.item.role }}</span>
          </template>

          <template v-slot:cell(lastLogin)="data">
            {{ $filters.formatDate(data.item.lastLogin as Date) }}
          </template>

          <template v-slot:cell(status)="data">
            <icon
              v-if="data.item.status === 'Inactive'"
              icon="x-circle"
              scale="1.25"
              variant="danger"
            ></icon>
            <icon
              v-if="data.item.status === 'Active'"
              icon="check-circle"
              scale="1.25"
              variant="primary"
            ></icon>
            <span
              class="d-inline-block"
              v-b-tooltip.hover.left.v-secondary
              title="Pending invite"
            >
              <icon
                v-if="data.item.status === 'Pending'"
                icon="-exclamation-circle"
                scale="1.25"
                variant="secondary"
              ></icon>
            </span>
          </template>

          <template v-slot:cell(action)="data">
            <b-dropdown
              v-if="!selectedRow[data.index]"
              id="company-account-dd"
              class="edit-dropdown"
              @click.prevent
              no-caret
            >
              <template #button-content>
                <icon icon="three-dots"></icon>
              </template>
              <b-dropdown-item-btn @click.stop="editSelectedRow(data.index)"
                >Edit</b-dropdown-item-btn
              >
              <b-dropdown-item-btn
                @click.stop="resendInvite(data.index, data.item as unknown as User)"
                >Resend invitation</b-dropdown-item-btn
              >
              <b-dropdown-item-button disabled v-if="viewAsUser"
                >View as user</b-dropdown-item-button
              >
              <b-dropdown-item-btn
                variant="danger"
                @click.stop="suspendUser(data.index, data.item as unknown as User)"
                >Suspend user</b-dropdown-item-btn
              >
            </b-dropdown>

            <b-button-group size="sm" v-else>
              <b-button
                pill
                variant="outline-secondary"
                class="mr-2"
                @click.stop="cancelUpdate(data.index)"
                >Cancel</b-button
              >
              <b-button
                pill
                variant="primary"
                @click.stop="updateUser(data.index, data.item as unknown as User)"
                >Save</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col class="d-flex flex-column">
          <b-pagination
            class="align-self-center align-self-xl-end"
            :total-rows="rows"
            :per-page="perPage"
            prev-text="Prev"
            next-text="Next"
            v-model="currentPage"
            first-number
            last-number
            aria-controls="companyusers"
            @change="handlePageChange"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <InviteUser />
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { fetchUsers } from "../../services/CompanyService";
import User from "../../models/User";
import InviteUser from "../administration/InviteUser.vue";
import { updateUser } from "../../services/UserService";
import { createUser } from "../../services/AdminService";
import moment from "moment";
import { isEmpty } from "lodash";
import { ref } from "vue";
import { useToast } from "vue-toastification";

@Component({
  components: {
    InviteUser,
  },
})
export default class CompanyUsersList extends Vue {
  @Prop()
  actionTitle!: string;
  @Prop()
  fields!: Array<object>;
  //   @Prop()
  //   roles!: Array<object>;
  @Prop()
  hasSearch!: boolean;
  @Prop()
  viewAsUser!: boolean;

  get rows() {
    return this.resultsReturned;
  }
  get company() {
    return this.$store.getters.company;
  }
  get user(): User {
    return this.$store.getters.authUser;
  }
  users: User[] = [];
  userSortSelected = "alpha";

  toast = useToast();

  perPage = 5;
  currentPage = 1;
  page = 1;
  updateTable = 0;
  resultsReturned = 0;
  userCount = 0;

  //   fields = [
  //     { key: "name", tdClass: "align-middle" },
  //     { key: "email", class: "limit-column-width", tdClass: "align-middle" },
  //     { key: "role", tdClass: "align-middle" },
  //     { key: "status", tdClass: "align-middle" },
  //     { key: "lastLogin", class: "d-lg-none d-xl-block v-align-date" },
  //     { key: "action", tdClass: "align-middle" },
  //   ];

  selectedRow: {[key:string]: any} = {}
  selectedRole = "User";
  userName = "";
  search = "";

  async mounted() {
    this.getUsers();
    this.emitter.on("newuserinvite", () => {
      this.getUsers();
    });
  }

  handlePageChange(value: number) {
    this.page = value;
    this.getUsers();
  }

  updateUsers(response: {
    users: User[];
    currentPage: number;
    userCount: number;
    resultsReturned: number;
  }) {
    this.users = response.users;
    this.currentPage = response.currentPage;
    this.resultsReturned = response.resultsReturned;
    this.userCount = response.userCount;
    this.updateTable += 1;
  }

  setPagingParams(search: string, page: number, perPage: number, sort: string) {
    const params: any = {};
    if (search) {
      params["search"] = search;
    }
    if (page) {
      params["page"] = page;
    }
    if (perPage) {
      params["size"] = perPage;
    }
    if (sort) {
      params["sort"] = sort;
    }
    return params;
  }

  async getUsers() {
    const params = this.setPagingParams(
      this.search,
      this.page,
      this.perPage,
      this.userSortSelected
    );
    const fetchResponse = await fetchUsers(this.company.companyId, params);
    this.updateUsers(fetchResponse);
  }

  resetSearch() {
    if (isEmpty(this.search)) {
      this.getUsers();
    }
  }

  rowClicked(record: User, idx: number) {
    this.editSelectedRow(idx);
  }
  editSelectedRow(idx: number) {
    this.selectedRow = {
      [idx]: true,
    };
  }

  cancelUpdate(idx: number) {
    this.selectedRow = {
      [idx]: false,
    };
  }

  async updateUser(idx: number, selectedUser: User) {
    const response = await updateUser(selectedUser.companyId, selectedUser.id, selectedUser);
    if(response === "OK"){
      this.toast.success("User updated successfully")
    } else {
      this.toast.error("User update failed, please try again")
    }
    this.selectedRow = {
      [idx]: false,
    };
  }

  inviteUser() {
    this.emitter.emit("invite-user");
  }

  async resendInvite(idx: number, selectedUser: User) {
    const newUser: Partial<User> = {};
    newUser.email = selectedUser.email;
    newUser.role = selectedUser.role;
    newUser.status = "Active";
    try {
      const response = await createUser(this.company.companyId, newUser);
      if (response === "success") {
        this.toast.success(
          newUser.email + " has been resent an invite to create an account!"
        );
      } else {
        this.toast.error("User resend invite failed, please try again");
      }
    } catch {
      this.toast.error("User resend invite failed, please try again");
    }
  }

  async suspendUser(idx: number, selectedUser: User) {
    //TODO make user inactive
    selectedUser.status = "Inactive";
    const response = await updateUser(selectedUser.companyId, selectedUser.id, selectedUser);
    if(response === "OK"){
      this.toast.success("User has been suspended")
    } else {
      this.toast.error("User update failed, please try again")
    }
    this.selectedRow = {
      [idx]: false,
    };
  }

  sortArray() {
    this.getUsers();
  }

  beforeUnmount() {
    this.emitter.off("newuserinvite");
  }
}
</script>

<style scoped>
:deep .dropdown .btn {
  background-color: transparent !important;
  color: #495057 !important;
  border: none;
}

:deep .dropdown .btn-secondary:focus,
.btn-secondary.focus {
  color: #495057;
  border-color: #545b62;
  box-shadow: none;
}

:deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #495057;
  background-color: transparent !important;
  border-color: none;
}

.edit-dropdown {
  background-color: transparent !important;
}

.custom-select {
  width: 80px;
}

.company-table {
  height: calc(100vh - 505px);
}

.company-table-height {
  height: calc(100vh - 550px);
  cursor: default;
}

.edit-text {
  width: 160px;
  height: 30px;
  font-size: 12px;
}

.labels {
  font-size: 16px;
  align-items: center;
}

.header {
  font-family: "proximanova-bold", sans-serif;
  font-size: 36px;
  border: 0;
  padding: 0;
  display: flex;
}
.b-container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /* padding: 1.25rem; */
}

:deep .table-b-table-default {
  font-family: "proximanova-bold", sans-serif;
  font-size: 16px;
  font-weight: normal !important;
}

:deep .v-align-date {
  padding-top: 0.65rem;
}

:deep .limit-column-width {
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* TODO if all tables follow this style move to main scss file */
:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

.form-control:focus {
    outline: 0 !important;
    box-shadow: none;
    background: #f9f9f9;
}
.search {
  background: #f9f9f9;
  border: 0;
  margin: 0.75rem 0.75rem 0.75rem 0;
}
.search-button {
  background: #f9f9f9;
  color: #898989;
  border: 0;
  outline: 0;
  border-radius: 0.5rem;
  box-shadow: none;
  margin: 0.75rem 0 0.75rem 0.75rem;
}

@media screen and (max-width: 576px) {
  .company-table {
    height: auto;
  }

  .company-table-height {
    /* height: calc(100vh - 550px); */
    cursor: default;
  }
}
</style>
