<template>
  <authentic-modal
    ref="billingDetailsModal"
    id="billing-details-modal"
    :on-cancel="cancel"
    size="md"
    :title="modalTitle"
    :hideFooter="true"
  >
    <b-container class="px-0 mx-0">
      <billing-details :on-cancel="cancel" :card-action="'save'">
        <template v-slot:billing-title>
          <b-card-text class="mb-2 pay-card">Credit card</b-card-text>
        </template>
      </billing-details>
    </b-container>
  </authentic-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-facing-decorator";
import AuthenticModal from "../shared/AuthenticModal.vue";
import BillingDetails from "../shared/BillingDetails.vue";

@Component({
  components: {
    AuthenticModal,
    BillingDetails,
  },
})
export default class CompanyBilling extends Vue {
  @Ref()
  billingDetailsModal!: AuthenticModal;
  @Ref()
  billingDetails!: BillingDetails;

  modalTitle = "Billing";

  created() {
    this.emitter.on("billing-details", () => {
      this.billingDetailsModal.show();
    });
    this.emitter.on("close-payment-modal", async () => {
      this.billingDetailsModal.hide();
    });
  }

  cancel() {
    this.billingDetailsModal.hide();
  }
}
</script>

<style scoped>
.pay-card {
  font-family: "proximanova-medium", sans-serif;
  font-size: 20px;
}
</style>
