<template>
  <authentic-modal
    ref="billingModal"
    id="billing-modal"
    :on-submit="submit"
    :on-cancel="cancel"
    :size="'lg'"
    :title="modalTitle"
    :hideFooter="true"
    :no-close-on-backdrop="true"
  >
    <b-overlay :show="showModalOverlay" rounded="sm">
      <b-container id="testsize" class="billing">
        <transition name="component-fade" mode="out-in">
          <div>
            <!--TODO test this!!!-->
            <BillingSummary
              v-if="billingSummary"
              :billing-details="billingInformation"
            />

            <BillingUpdatePlan
              v-if="billingUpdate"
              :billing-details="billingInformation"
            />

            <BillingAddPayment v-if="addPayment" />
            <BillingProductQuantity
              v-if="updateQuantity"
              :billing-details="billingInformation"
              :unarchive="unarchive"
            />
            <BillingCancelPlan
              v-if="billingCancelPlan"
              :billing-details="billingInformation"
            />
          </div>
          <!--TODO test this end!!!-->
        </transition>
      </b-container>
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <p class="payment-waiting">{{ overlayMessage }}</p>
        </div>
      </template>
    </b-overlay>
  </authentic-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-facing-decorator";
import AuthenticModal from "../shared/AuthenticModal.vue";
import {
  getCustomerStatus,
  setDefaultPayment,
  removePayment,
} from "../../services/PaymentService";
import User from "../../models/User";
import Billing from "../../models/Billing";
import BillingUpdatePlan from "./BillingUpdatePlan.vue";
import BillingSummary from "./BillingSummary.vue";
import BillingAddPayment from "./BillingAddPayment.vue";
import BillingProductQuantity from "./BillingProductQuantity.vue";
import BillingCancelPlan from "./BillingCancelPlan.vue";

@Component({
  components: {
    AuthenticModal,
    BillingUpdatePlan,
    BillingSummary,
    BillingAddPayment,
    BillingProductQuantity,
    BillingCancelPlan,
  },
})
export default class BillingModal extends Vue {
  @Ref()
  billingModal!: AuthenticModal;

  get user(): User {
    return this.$store.getters.authUser;
  }

  show = true;
  showOverlay = false;
  showModalOverlay = false;
  paymentMethodMessage = "";
  overlayMessage = "Getting account information...";

  //Transitions vars
  billingUpdate = false;
  billingSummary = true;
  addPayment = false;
  updateQuantity = false;
  billingCancelPlan = false;

  fields = [
    { key: "brand", tdClass: ["align-middle", "set-card-spacing"] },
    { key: "exp_month", tdClass: ["align-middle", "set-expire-spacing"] },
    { key: "action", tdClass: ["align-middle", "text-center"] },
  ];

  modalTitle = "Billing";
  billingInformation: Partial<Billing> | null = {};
  unarchive = false;

  created() {
    this.$store.dispatch("getPlans");
    this.emitter.on("billing-modal-close", () => {
      this.billingModal.hide();
    });

    this.emitter.on("update-plan", () => {
      this.billingSummary = false;
      this.billingUpdate = true;
      this.addPayment = false;
      this.updateQuantity = false;
      this.billingCancelPlan = false;
    });

    this.emitter.on("billing-summary", async (update: string) => {
      if (update === "refresh") {
        this.overlayMessage = "Getting updated account information...";
        this.showModalOverlay = true;
        this.billingInformation = await getCustomerStatus(this.user);
        this.showModalOverlay = false;
      }
      this.billingSummary = true;
      this.billingUpdate = false;
      this.addPayment = false;
      this.updateQuantity = false;
      this.billingCancelPlan = false;
    });

    this.emitter.on("billing-add-payment", () => {
      this.billingSummary = false;
      this.billingUpdate = false;
      this.addPayment = true;
      this.updateQuantity = false;
      this.billingCancelPlan = false;
    });

    this.emitter.on(
      "billing-update-quantity",
      async (updateQuantity: {productList: boolean, fromArchive: boolean}) => {
        this.billingSummary = false;
        this.billingUpdate = false;
        this.addPayment = false;
        this.billingCancelPlan = false;
        if (updateQuantity.productList) {
          this.showModalOverlay = true;
          this.billingModal.show();
          this.billingInformation = await getCustomerStatus(this.user);
          this.showModalOverlay = false;
        }
        this.unarchive = updateQuantity.fromArchive;
        this.updateQuantity = true;
      }
    );

    this.emitter.on("billing-modal", async () => {
      this.billingSummary = true;
      this.billingUpdate = false;
      this.addPayment = false;
      this.updateQuantity = false;
      this.billingCancelPlan = false;

      this.showModalOverlay = true;
      if (this.billingModal) {
        this.billingModal.show();
      }
      this.billingInformation = await getCustomerStatus(this.user);
      this.showModalOverlay = false;
    });

    this.emitter.on("cancel-plan", async () => {
      this.billingSummary = false;
      this.billingUpdate = false;
      this.addPayment = false;
      this.updateQuantity = false;
      this.billingCancelPlan = true;
      this.billingInformation = await getCustomerStatus(this.user);
    });
  }

  submit() {
    this.billingModal.hide();
  }

  cancel() {
    this.billingModal.hide();
  }

  beforeUnmount() {
    this.emitter.off("billing-management")
    this.emitter.off("billing-modal");
    this.emitter.off("billing-modal-close");
  }
}
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter-from, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

:deep .tooltip-inner {
  text-align: left;
}

.payment-methods {
  min-height: 105px;
}

.billing {
  height: 575px !important;
  padding: 0;
  margin: 0;
}

.history-container {
  overflow: auto;
  max-height: 136px;
}

.default-action {
  opacity: 0.5;
}

:deep .set-card-spacing {
  padding-right: 3rem !important;
}
:deep .set-expire-spacing {
  padding-right: 1.5rem !important;
}

:deep .b-table-sticky-header {
  max-height: 125px;
}

:deep .dropdown .btn {
  background-color: transparent !important;
  color: #495057 !important;
  border: none;
}

:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

:deep .dropdown .btn-secondary:focus,
.btn-secondary.focus {
  color: #495057;
  border-color: #545b62;
  box-shadow: none;
}

:deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #495057;
  background-color: transparent !important;
  border-color: none;
}

h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}
.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.subscription-renewal {
  margin-top: -0.5rem;
  font-family: "proximanova-regular", sans-serif;
  font-size: 16px;
  white-space: nowrap;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}
.edit-dropdown {
  background-color: transparent !important;
}
</style>
