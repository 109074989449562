/**
 * Vuex store for subscription plans
 */
import Plan from "../../models/Plan";
import { Commit } from "vuex";
import { getPlans } from "@/services/PaymentService";

type PlanState = {
  plans: Plan;
};

const initialState = {
  plans: [],
};

export default {
  state: initialState,

  getters: {
    plans(state: PlanState) {
      return state.plans;
    },
  },

  mutations: {
    setPlans(state: PlanState, plans: Plan) {
      state.plans = plans;
    },
  },

  actions: {
    async getPlans({ commit }: { commit: Commit }) {
      try {
        const plans = await getPlans();
        commit("setPlans", plans);
      } catch (error) {
        console.error("Error retrieving plan types: ", error);
      }
    },
  },
};
