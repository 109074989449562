<template>
  <authentic-modal
    ref="companyUsersModal"
    id="company-users-modal"
    size="lg"
    :title="modalTitle"
    :hide-footer="true"
  >
    <company-users-list
      :fields="fields"
      :action-title="'Add new user'"
      :has-search="true"
    >
    </company-users-list>
  </authentic-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-facing-decorator";
import AuthenticModal from "../shared/AuthenticModal.vue";
import CompanyUsersList from "./CompanyUsersList.vue";
import User from "../../models/User";

@Component({
  components: {
    AuthenticModal,
    CompanyUsersList,
  },
})
export default class CompanyUsers extends Vue {
  @Ref()
  companyUsersModal!: AuthenticModal;

  modalTitle = "User management";

  fields = [
    { key: "name", tdClass: "align-middle" },
    { key: "email", class: "limit-column-width", tdClass: "align-middle" },
    { key: "role", tdClass: "align-middle" },
    {
      key: "status",
      tdClass: ["align-middle", "text-center"],
      thClass: "text-center",
    },
    { key: "action", label: "", tdClass: "align-middle" },
  ];

  //   roles = [
  //     { value: "Admin", text: "Admin" },
  //     { value: "User", text: "User" },
  //   ];

  created() {
    this.emitter.on("user-management", async () => {
      this.companyUsersModal.show();
    });
  }

  beforeUnmount() {
    this.emitter.off("user-management");
  }
}
</script>

<style scoped></style>
