<template>
  <b-container class="pb-4 px-4">
    <b-row no-gutters>
      <b-col>
        <h5 class="current">Current subscription plan</h5>
      </b-col></b-row
    >
    <b-row>
      <b-col>
        <b-row no-gutters
          ><b-card-text class="subscription-plan">{{
            billingDetails.subscriptionName
          }}</b-card-text></b-row
        >
        <b-row class="pb-0" no-gutters>
          <b-card-text class="subscription-price"
            >${{ billingDetails.planPrice }} /
            {{ billingDetails.paymentInterval }}</b-card-text
          >
        </b-row>

        <b-row no-gutters v-if="billingDetails.cancellationDate">
          <b-card-text class="subscription-renewal"
            >Your current plan terminates on
            {{ billingDetails.cancellationDate }}</b-card-text
          >
        </b-row>
        <b-row no-gutters v-else>
          <b-card-text class="subscription-renewal"
            >Your plan renews on {{ billingDetails.renewalDate }}</b-card-text
          >
        </b-row>
      </b-col>
      <b-col class="text-right" v-if="billingDetails.cancellationDate">
        <span
          class="d-inline-block"
          v-b-tooltip.hover.left.v-secondary
          title="Not implemented. Your current plan is scheduled to be cancelled on the provided date. If you wish to resume the plan please content Authentic Labs support"
        >
          <b-button
            pill
            variant="outline-primary"
            size="sm"
            class="mx-2"
            disabled
            >Resume plan</b-button
          >
        </span>
      </b-col>
      <b-col class="text-right billing-buttons" v-else>
        <b-button-group>
          <b-button
            pill
            variant="outline-secondary"
            size="sm"
            @click="cancelPlan"
            >Cancel plan</b-button
          >
          <b-button
            pill
            variant="primary"
            size="sm"
            class="mx-2"
            @click="updatePlan"
            >Update plan</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>

    <b-row class="pt-5 current align-self-center" no-gutters>
      <b-col class="col-5 col-md-3">
        <h5 style="margin-bottom: 0px !important">Payment method</h5>
      </b-col>
      <b-col>
        <b-button pill size="sm" variant="outline-primary" @click="addPayment"
          >Add payment method</b-button
        >
      </b-col>
    </b-row>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-row no-gutters class="payment-methods">
        <!-- @vue-ignore -->
        <b-table
          ref="paymentMethods"
          id="payment-methods"
          class="w-auto"
          thead-class="d-none"
          sticky-header
          small
          no-border-collapse
          borderless
          responsive
          primary-key="id"
          :fields="fields"
          :items="billingDetails.paymentMethods"
        >
          <template v-slot:cell(brand)="data">
            <span class="pr-1">{{
              $filters.capitalizeFirst(data.item.brand as string)
            }}</span>
            <span class="pr-1"
              ><span>&bull;&bull;&bull;&bull;</span>
              {{ data.item.last_four }}</span
            >
            <span v-if="data.item.default" class="default">Default</span>
          </template>

          <template v-slot:cell(exp_month)="data">
            <span class="pr-1"
              >Expires {{ $filters.formatMonth(data.item.exp_month as number) }}/{{
                data.item.exp_year
              }}</span
            >
          </template>

          <template v-slot:cell(action)="data">
            <b-form-text
              v-if="data.item.default"
              v-b-tooltip="{
                title:
                  'Default payment method cannot be deleted because you have an active plan',
                placement: 'left',
                variant: 'secondary',
              }">
              <!-- <icon icon="x" scale="1.5"></icon> -->
              <i icon="x"></i>
            </b-form-text>
            <b-dropdown
              v-else
              id="payment-method-dd"
              class="edit-dropdown"
              @click.prevent
              no-caret
            >
              <template #button-content>
                <icon icon="three-dots"></icon>
              </template>
              <b-dropdown-item-btn
                @click.stop="makeDefault(data.index, data.item.id as string)"
                >Make default</b-dropdown-item-btn
              >
              <b-dropdown-item-btn
                variant="danger"
                @click.stop="deletePaymentMethod(data.index, data.item.id as string)"
                >Delete</b-dropdown-item-btn
              >
            </b-dropdown>
          </template>
        </b-table>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <p class="payment-waiting">{{ paymentMethodMessage }}</p>
        </div>
      </template>
    </b-overlay>

    <b-row class="pt-4" no-gutters>
      <b-col>
        <h5 class="current">Billing history</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="history-container">
        <!-- @vue-ignore -->
        <b-card no-body border-variant="0"
          class="py-2"
          v-for="(billingRecord, index) in billingDetails.billingHistory"
          :key="index"
        >
          <b-row>
            <b-col class="col-4 col-md-3">
              <b-card-text class="">{{ billingRecord.date }} </b-card-text>
            </b-col>
            <b-col class="col-3 col-md-3 col-lg-2">
              <b-card-text>{{ billingRecord.amount }} </b-card-text>
            </b-col>
            <b-col class="overflow-ellipses">
              <b-card-text
                class="overflow-ellipses"
                v-b-tooltip.hover.top.v-light
                :title="billingRecord.description"
                >{{ billingRecord.description }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from "vue-facing-decorator";
import {
  getCustomerStatus,
  setDefaultPayment,
  removePayment,
} from "../../services/PaymentService";
import User from "../../models/User";
import Billing from "../../models/Billing";

@Component({
  components: {},
})
export default class BillingSummary extends Vue {
  @Prop()
  billingDetails!: Billing;

  get user(): User {
    return this.$store.getters.authUser;
  }

  show = true;
  showOverlay = false;
  showMainOverlay = false;
  paymentMethodMessage = "";

  fields = [
    { key: "brand", tdClass: ["align-middle", "set-card-spacing"] },
    { key: "exp_month", tdClass: ["align-middle", "set-expire-spacing"] },
    { key: "action", tdClass: ["align-middle", "text-center"] },
  ];

  modalTitle = "Billing";

  beforeUnmount() {
    this.emitter.off("billing-management")
  }

  mounted(){
    // console.log(this.billingDetails)
  }

  updatePlan() {
    this.emitter.emit("update-plan");
  }

  cancelPlan() {
    this.emitter.emit("cancel-plan");
  }

  addPayment() {
    this.emitter.emit("billing-add-payment");
  }

  async makeDefault(idx: number, paymentMethod: string) {
    this.paymentMethodMessage = "Updating payment information...";
    this.showOverlay = true;
    const response = await setDefaultPayment({ paymentMethod });
    if (response?.status === "success") {
      this.billingDetails!.paymentMethods = response?.paymentMethods;
    }
    this.showOverlay = false;
  }

  async deletePaymentMethod(idx: number, paymentMethod: string) {
    this.paymentMethodMessage = "Deleting selected payment method...";
    this.showOverlay = true;
    const response = await removePayment({ paymentMethod });
    if (response?.status === "success") {
      this.billingDetails!.paymentMethods = response?.paymentMethods;
    }
    this.showOverlay = false;
  }
}
</script>

<style scoped>
:deep .tooltip-inner {
  /* max-width: 400px !important; */
  text-align: left;
}

.payment-methods {
  min-height: 105px;
}

.history-container {
  overflow: auto;
  max-height: 136px;
}

.billing-buttons {
  position: absolute;
  right: 0;
}

.overflow-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-action {
  opacity: 0.5;
}

:deep .set-card-spacing {
  padding-right: 3rem !important;
}
:deep .set-expire-spacing {
  padding-right: 1.5rem !important;
}

:deep .b-table-sticky-header {
  max-height: 125px;
}

:deep .dropdown .btn {
  background-color: transparent !important;
  color: #495057 !important;
  border: none;
}

:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

:deep .dropdown .btn-secondary:focus,
.btn-secondary.focus {
  color: #495057;
  border-color: #545b62;
  box-shadow: none;
}

:deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #495057;
  background-color: transparent !important;
  border-color: none;
}
h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}
.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.subscription-renewal {
  margin-top: -0.5rem;
  font-family: "proximanova-regular", sans-serif;
  font-size: 16px;
  white-space: nowrap;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}
.edit-dropdown {
  background-color: transparent !important;
}
</style>
