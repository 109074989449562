/**
 * Vuex store for subscription plans
 */
import { Commit } from "vuex";

type Gs1State = {
  settings: any;
  currentBatch: any;
};

const initialState = {
  settings: [],
  currentBatch : {},
};

export default {
  state: initialState,

  getters: {
    gs1settings(state: Gs1State) {
      return state.settings;
    },
    currentBatch(state: Gs1State) {
      return state.currentBatch;
    }
  },
  

  mutations: {
    setGs1Settings(state: Gs1State, settings: any) {
      state.settings = settings;
    },
    setCurrentBatch(state: Gs1State, batch: any) {
      state.currentBatch = batch;
    }
  },

//   actions: {
//     async getPlans({ commit }: { commit: Commit }) {
//       try {
//         const plans = await getPlans();
//         commit("setPlans", plans);
//       } catch (error) {
//         console.error("Error retrieving plan types: ", error);
//       }
//     },
//   },
};
