<template>
  <!-- @vue-ignore -->
  <b-modal
    ref="authenticModal"
    :id="id"
    :size="modalSize"
    header-bg-variant="transparent"
    header-text-variant="dark"
    header-border-variant="0"
    cancel-variant="flat"
    :title="title"
    @hide="cancelClicked"
    @ok.prevent="okClicked"
    @cancel="cancelClicked"
    centered
    :hide-footer="hideFooter"
    :no-close-on-backdrop="backDropClose"
  >
    <slot />
    <template v-slot:modal-header-close>
      <i  class="bi bi-x-circle close-modal"></i>
      <!-- <i icon="x-circle" class="close-modal"></i> -->
      <!-- <icon icon="x-circle" scale="1.05" class="close-modal"></icon> -->
    </template>
    <template v-slot:modal-footer>
      <slot name="modal-footer"></slot>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { BModal } from "bootstrap-vue";
import { Component, Prop, Ref, Vue } from "vue-facing-decorator";

@Component
export default class AuthenticModal extends Vue {
  @Ref()
  authenticModal!: BModal;
  @Prop()
  id!: string;

  @Prop()
  title!: string;

  @Prop()
  onSubmit?: () => void;

  @Prop()
  onCancel?: () => void;

  @Prop()
  size!: string;

  @Prop()
  hideFooter!: boolean;

  @Prop()
  noCloseOnBackdrop!: boolean;

  get modalSize(): string {
    return this.size ? this.size : "lg";
  }

  get backDropClose(): boolean {
    if (this.noCloseOnBackdrop) {
      return this.noCloseOnBackdrop;
    }
    return false;
  }

  get footer(): boolean {
    if (this.hideFooter) {
      return this.hideFooter;
    }
    return false;
  }

  show() {
    // this.authenticModal.show(this.id);
    this.authenticModal.show();
  }

  hide() {
    this.authenticModal.hide(this.id);
  }

  okClicked() {
    if (this.onSubmit) {
      this.onSubmit();
    }
  }

  cancelClicked() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.hide();
  }
}
</script>

<style scoped>

.close-modal {
  /* color: #898989; */
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 1rem;
  font-size:32px;
  display: inline-block;
  text-shadow: 0 0 BLACK;
}

@media screen and (max-width: 576px) {
  :deep .modal-content {
    height: 80% !important;
    width: 90% !important;
    left: 15px !important;
  }
  :deep .modal-body {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) {
  :deep .modal-content {
    height: 80% !important;
    width: 100% !important;
    left: 15px !important;
  }
  :deep .modal-body {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* :deep .modal-content {
  height:80% !important;
  width:100% !important;
  left:15px !important;
} */
  :deep .modal-body {
    overflow-y: scroll;
  }
}
</style>
