<template>
  <div class="avatar-container">
    <b-img
      v-if="user.avatar"
      id="avpic"
      :src="avatarUrl"
      class="profile-avatar img-fluid d-block"
    >
    </b-img>
    <b-dropdown
      @change="updateAvatar"
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      class="icon-dropdown"
      no-caret
    >
      <template #button-content>
        <i class="bi bi-plus-circle-fill"></i>
      </template>

      <b-dropdown-item
        v-for="avatar in avatars"
        :key="avatar.value"
        @click="user.avatar = avatar.value"
      >
        <b-img
          v-if="avatar.value"
          id="avpic"
          :src="avatarValue(avatar.value)"
          class="pr-2"
        ></b-img>
        {{ avatar.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import User from "../../models/User";

interface Avatar {
  text: string;
  value: string;
}

@Component
export default class AvatarSelector extends Vue {
  get user(): User {
    return this.$store.getters.authUser;
  }
  get avatarUrl(): string {
    return new URL(`/src/assets/images/avatars/${this.user.avatar}`, import.meta.url).href
  }

  avatars: Avatar[] = [
    { text: "Bot 1", value: "bot1.jpg" },
    { text: "Bot 2", value: "bot2.jpg" },
    { text: "Bot 3", value: "bot3.jpg" },
    { text: "Bot 4", value: "bot4.jpg" },
  ];

  updateAvatar(avatar: string) {
    this.user.avatar = avatar;
  }

  avatarValue(value: string) {
    return new URL(`/src/assets/images/avatars/sm_${value}`, import.meta.url).href;
  }
}
</script>

<style scoped>
.avatar-container {
  position: relative;
}
.profile-avatar {
  border: 0;
  border-radius: 10px;
  width: 150px;
}
.icon-dropdown {
  position: absolute;
  top: -20px;
  left: -25px;
}
:deep .dropdown-item {
  font-family: "proximanova-regular", sans-serif;
}
:deep .btn:focus,
.btn.focus {
  box-shadow: none;
}
</style>
