/**
 * Vuex store for ctas
 */
import { Cta } from "@authentic-labs/survey-builder"

type CTAState = {
  selectedCTA: Cta;
  selectedCTAEntries: number;
};

const initialState = {
  selectedCTA: {},
};

export default {
  state: initialState,

  getters: {
    selectedCTA(state: CTAState) {
      return state.selectedCTA;
    },
    selectedCTAEntries(state: CTAState) {
      return state.selectedCTAEntries;
    },
  },

  mutations: {
    setSelectedCTA(state: CTAState, selectedCTA: Cta) {
      state.selectedCTA = selectedCTA;
    },
    setSelectedCTAEntries(state: CTAState, entries: number) {
      state.selectedCTAEntries = entries;
    },
  },
};
