<template>
  <!-- TODO can break down several common components here contact info, address info go through and refacter once working. -->
  <b-container class="details-container">
    <b-container v-if="adminType === 'sadmin'">
      <b-row no-gutters>
        <!-- @vue-ignore -->
        <b-card no-body border-variant="0" class="p-3 col-xl-8">
          <b-form ref="company-details-form">
            <b-form-row class="pb-4">
              <b-col lg="4" class="pt-1">
                <ImageSelector
                  class="company-logo"
                  ref="logoImage"
                  :image-src="companyLogoFile"
                />
              </b-col>
              <b-col>
                <b-form-row>
                  <b-col>
                    <!-- <b-form-group
                      id="name-input"
                      class="labels labels-names my-0"
                      label-for="company-name"
                      label-cols="12"
                      content-cols="12"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span>Name
                      </template>
                      <b-form-input
                        v-model="company.companyName"
                        id="company-name"
                        class="form-input"
                        placeholder="Enter your company's name..."
                        required
                      ></b-form-input>
                    </b-form-group> -->
                    <b-form-group
                      id="name-input"
                      class="labels labels-names my-0"
                      label-for="company-name"
                      label-cols="12"
                      content-cols="12"
                    >
                      <template v-slot:label>
                        <span class="text-danger">*</span>Name
                      </template>
                      <b-form-input
                        v-model="(companyName.value.value as string)"
                        id="company-name"
                        class="form-input"
                        placeholder="Enter your company's name..."
                        :state="companyName.errorMessage.value ? false : null"
                        required
                        @blur.native="adminNameChange"
                      ></b-form-input>
                      <b-form-invalid-feedback id="company-name-live-feedback">{{
                      companyName.errorMessage.value
                    }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <slot name="company-listing-type-selector"></slot>
                  </b-col>
                  <b-col>
                    <slot name="company-status-selector"></slot>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="col-5">
                <b-form-group
                  label="Industry"
                  label-for="co-industry"
                  class="labels labels-names my-0"
                  label-cols="12"
                  content-cols="12"
                >
                  <b-form-select
                    id="co-industry"
                    name="co-industry"
                    v-model="company.industry"
                  >
                    <b-form-select-option
                      v-for="(industry, index) of industries"
                      :key="index"
                      :value="industry"
                      >{{ industry }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <slot name="sales-rep"></slot>
            </b-form-row>
            <slot name="admin-notes"></slot>
          </b-form>
        </b-card>
        <!-- TODO create a stats component that can be resused!!! -->
        <!-- TODO for now just a slot -->
        <slot name="admin-stats"></slot>
        <!-- TODO end of stats markup -->
      </b-row>
    </b-container>

    <b-container v-if="adminType === 'admin'">
      <!-- @vue-ignore -->
      <b-card no-body border-variant="0" class="p-3 col-xl-12">
        <b-form ref="company-details-form">
          <b-form-row class="pb-4">
            <b-col md="5" lg="4" class="pt-1">
              <ImageSelector
                class="company-logo"
                ref="logoImage"
                :image-src="companyLogoFile"
                size="sm"
                :up-load="isAdmin"
                button-location="ul"
                @change="onLogoChange"
              />
            </b-col>
            <b-col>
              <b-form-row>
                <b-col>
                  <b-form-group
                    id="name-input"
                    class="labels labels-names my-0"
                    label-for="company-name"
                    label-cols="12"
                    content-cols="12"
                  >
                    <template v-slot:label>
                      <span class="text-danger">*</span>Name
                    </template>

                    <b-form-input
                      v-model="(companyName.value.value as string)"
                      id="company-name"
                      class="form-input"
                      placeholder="Enter your name..."
                      required
                      :state="companyName.errorMessage.value ? false : null"
                    ></b-form-input>
                    <b-form-invalid-feedback id="company-name-live-feedback">{{
                      companyName.errorMessage.value
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col class="col-12">
                  <b-form-group
                    label-for="co-industry"
                    class="labels labels-names my-0"
                    label-cols="12"
                    content-cols="12"
                    label="Industry"
                  >
                    <b-form-select
                      id="co-industry"
                      name="co-industry"
                      v-model="company.industry"
                    >
                      <b-form-select-option
                        v-for="(industry, index) of industries"
                        :key="index"
                        :value="industry"
                        >{{ industry }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
          </b-form-row>

          <b-row>
            <b-col
              ><h5 class="mb-0">
                <span class="text-danger">*</span>Contact Information
              </h5></b-col
            >
          </b-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="website-label"
                class="labels labels-names my-0"
                label="Website"
                label-for="website-input"
                label-cols="12"
                content-cols="12"
              >
                <b-form-input
                  v-model="(companyUrl.value.value as string)"
                  id="website-input"
                  class="form-input"
                  placeholder="Enter your website..."
                  required
                  :state="companyUrl.errorMessage.value ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback id="website-live-feedback">{{
                  companyUrl.errorMessage.value
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="8">
              <b-form-group
                id="email-input"
                class="labels"
                label="Email"
                label-for="email"
                content-cols="12"
              >
                <b-form-input
                  id="email"
                  class="form-input"
                  v-model="(companyEmail.value.value as string)"
                  placeholder="Enter your email..."
                  required
                  :state="companyEmail.errorMessage.value ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback id="email-live-feedback">{{
                  companyEmail.errorMessage.value
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="phone-number"
                class="labels"
                label="Phone"
                label-for="phonenumber"
                content-cols="12"
              >
                <b-form-input
                  id="phonenumber"
                  class="form-input"
                  v-model="(companyTelephone.value.value as string)"
                  placeholder="xxx-xxx-xxxx"
                  required
                  :state="companyTelephone.errorMessage.value ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback id="phone-live-feedback">{{
                  companyTelephone.errorMessage.value
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-row>
            <b-col
              ><h5 class="mb-0">
                <span class="text-danger">*</span>Address
              </h5></b-col
            >
          </b-row>
          <b-form-row>
            <b-col lg="8">
              <b-form-group
                class="labels"
                label="Street address"
                label-for="street-address-1"
                content-cols="12"
              >
                <b-form-input
                  id="street-address-1"
                  placeholder="Street address..."
                  v-model="(companyStreetAddress.value.value as string)"
                  required
                  :state="
                    companyStreetAddress.errorMessage.value ? false : null
                  "
                ></b-form-input>
                <b-form-invalid-feedback id="street-address-1-live-feedback">{{
                  companyStreetAddress.errorMessage.value
                }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="labels"
                label="Country"
                label-for="country"
                content-cols="12"
              >
                <country-select
                  id="country"
                  v-model="company.country"
                  :country="company.country"
                  topCountry="US"
                  class="form-control"
                  removePlaceholder
                />
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group
                class="labels"
                label="State"
                label-for="state"
                content-cols="12"
              >
                <region-select
                  id="state"
                  v-model="(companyRegion.value.value as string)"
                  :country="company.country"
                  :region="companyRegion.value.value"
                  class="form-control"
                  autocomplete="address-level1"
                />
                <small class="form-text text-danger">{{
                  companyRegion.errorMessage.value
                }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col lg="8">
              <b-form-group
                class="labels"
                label="City"
                label-for="street-address-2"
                content-cols="12"
              >
                <b-form-input
                  id="street-address-2"
                  v-model="company.city"
                  placeholder="City..."
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="labels"
                label="Zip code"
                label-for="zip-code"
                content-cols="12"
              >
                <b-form-input
                  id="zip-code"
                  v-model="company.postcode"
                  placeholder="xxxxx"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card>
    </b-container>
  </b-container>
</template>

<script setup lang="ts">
import ImageSelector from "../shared/ImageSelector.vue";
import { Company } from "@authentic-labs/vue-component-library";
import { fetchIndustries } from "../../services/IndustriesService";
import { uploadFile } from "../../services/FileService";
import Industry from "../../models/Industry";
import User from "../../models/User";
import moment from "moment";

import { useField, useForm } from "vee-validate";
import { useStore } from "vuex";
import { object, string } from "yup";
import { inject, reactive, watch } from "vue";
import { computed, markRaw, onMounted, ref } from "vue";

const logoImage = ref<ImageSelector>();

const props = defineProps<{
  adminType: string;
  isSubmitting?: boolean;
}>();

watch(
  () => props.isSubmitting,
  (currentValue, previousValue) => {
    if (currentValue === true) {
      checkFormValidity();
    }
  }
);

const $store = useStore();
const emitter: any = inject("emitter");

const company = computed(() => {
  return $store.getters.company;
});
const published = computed(() => {
  const published = company.value.published;
  return published ? "Yes" : "No";
});
const companyLogoFile = computed(() => {
  if (company.value.logoFile) {
    return company.value.logoFile.fileUrl;
  }
  return "";
});
const companyStats = computed(() => {
  return $store.getters.stats;
});
const isAdmin = computed(() => {
  return props.adminType === "admin" ? true : false;
});
const user = computed(() => {
  return $store.getters.authUser;
});


// TODO validation works however the documentation for the updated vee-validation is a big mess and utterly not understandable
// TODO kind of flows along with the vue2 to vue3 migration. The new version is essentially complete rewrite - sucks. 
// TODO at any rate the validation schema is properly set for the company object however setField and/or setDefineBinds does not work
// TODO as you would expect it to. So instead of really taking advantage of reactivity I needed to introduce each individual field using 
// TODO use fields. If I had not spent so much time trying to come up to speed on a rewrite of a software package that worked perfectly before
// TODO I would move to something else. TOO MUCH TIME and leaving this as is - state is - it works but can likely be improved.  
const validationSchema = markRaw(
  object({
    company: object({
      companyName: string().required("Company name is a required field"),
      url: string()
        .required("Company website is a required field")
        .url("Please provide a valid URL"),
      email: string()
        .required("Company email is a required field")
        .email("Please provide a valid email"),
      telephone: string()
        .required("Company telephone is a required field")
        .trim(),
      streetAddress: string()
        .required("Company street address is a required field")
        .trim(),
      state: string()
        .required("Company region/state is a required field")
        .notOneOf(["Select Region"]),
    }),
  })
)
const { validate, errors, setErrors, defineInputBinds } = useForm({ validationSchema });
const companyName = useField("company.companyName", validationSchema);
const companyUrl = useField("company.url", validationSchema);
const companyEmail = useField("company.email", validationSchema);
const companyTelephone = useField("company.telephone", validationSchema);
const companyStreetAddress = useField("company.streetAddress", validationSchema);
const companyRegion = useField("company.state", validationSchema);
// TODO END validation rant

let industries = ref<Industry[]>([]);
let logoFile: File | null = null;

const adminNotes = "This is a notes example";
const country = "US";
const region = "CO";

onMounted(async () => {
  industries.value = await fetchIndustries();
  companyName.value.value = company.value.companyName;
  companyUrl.value.value = company.value.url;
  companyEmail.value.value = company.value.email;
  companyTelephone.value.value = company.value.telephone ? company.value.telephone : "";
  companyStreetAddress.value.value = company.value.streetAddress
  companyRegion.value.value = company.value.state
});

async function submit() {
  if (logoFile) {
    const file = await uploadFile(logoFile);
    company.value.logoFile = file;
  }
}

function adminNameChange() {
  emitter.emit("adminNameChange", companyName.value.value);
}

async function checkFormValidity() {
  const { valid, errors } = await validate();
  if(valid){
    company.value.companyName = companyName.value.value;
    company.value.url = companyUrl.value.value;
    company.value.email = companyEmail.value.value;
    company.value.telephone = companyTelephone.value.value;
    company.value.streetAddress = companyStreetAddress.value.value;
    company.value.state = companyRegion.value.value;
    $store.commit("setCompany", company.value);
  }
  emitter.emit("submitCompanyDetails", valid);
}

function onLogoChange() {
  if (logoImage.value?.imageFile) {
    logoFile = logoImage.value.imageFile;
    $store.commit("setCompanyLogoFile", company.value.logoFile);
    submit();
  }
}
</script>

<style scoped>
.company-logo {
  width: 150px;
  height: 150px;
}
.labels-names {
  font-size: 16px !important;
}

.details-container {
  overflow: auto;
  height: calc(100vh - 375px);
  margin-bottom: 0rem;
}

.form-input {
  border-radius: 8px;
}
.labels {
  font-size: 18px;
}

.stats-header {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}
.stats-label {
  font-family: "proximanova-bold", sans-serif;
  font-size: 16px;
}
.stats-body {
  background-color: #e5e5e5;
  border-radius: 0.5rem;
}
:deep .btn:focus,
.btn.focus {
  box-shadow: none;
}
.footer-button {
  height: 30px;
  font-size: 16px;
  line-height: 14px;
}

@media screen and (max-width: 576px) {
  .details-container {
    overflow: auto;
    height: auto;
    margin-bottom: 0rem;
  }
}
</style>
