/**
 * Service for product API calls
 */
import User from "@/models/User";
import { apiClient } from "./Api";

// @ts-ignore
import { mapKeys } from "lodash"; //For whatever unknown wild reason when I compile I am getting mapKeys is not available from lodash - it is and still does the job it is suppose.
//error like this are absolutely ridiculous and waste hours of time wth?

export async function register(payLoad: object) {

  await apiClient.get("sanctum/csrf-cookie");
  const { statusText: response } = await apiClient.post("/register", payLoad);
  return response;
}

//TODO update name to resetPassword
export async function update(payLoad: object) {
  //TODO We want local to camelCase, however some API/backend logic
  //TODO requires snake, such as password_confirmation, quickfix
  //TODO is to transform the object as below. Long term fix is
  //TODO address this through axios request interceptor and ensure
  //TODO the backend is set up correctly via middleware
  // eslint-disable @typescript-eslint/camelcase
  const transformedPayload = mapKeys(payLoad, (_value: any, key: string) => {
    if (key === "passwordConfirmation") return "password_confirmation";
    return key;
  });
  const { statusText: response } = await apiClient.post<string>(
    "/email/reset",
    transformedPayload
  );
  await apiClient.get("sanctum/csrf-cookie");
  return response;
}

export async function login(payLoad: {
  email: string;
  password: string;
  remember?: boolean;
}) {
  /* 
    Removing the remember property if it is false. Laravel seems to only check 
    if the property exists - if it exists Laravel provides a remember_me token
    whether the value is true or false. So if remember property is false, just 
    remove it from the object so we dont get a remember_me token and things work
    as expected.
  */
  if (!payLoad.remember) {
    delete payLoad.remember;
  }
  await apiClient.get("sanctum/csrf-cookie");
  try {
    const { statusText: response } = await apiClient.post<string>(
      "/login",
      payLoad
    );
    // console.log(response, "response in auth service")
    return response;
  } catch (error) {
    console.log("error in auth service", error)
    return "error"
    // const { data: resetRequired } = await apiClient.post("/api/userReset", {
    //   email: payLoad.email,
    // });
    // if (resetRequired.status) {
    //   return "RESET_REQUIRED";
    // } else {
    //   throw new Error("login error");
    // }
  }
}

export async function logout() {
  const logout = apiClient.post("/logout");
  await apiClient.get("sanctum/csrf-cookie");
  return logout;
}

export async function fetchUser() {
  const { data: user } = await apiClient.get<User>("/api/user");
  return user;
}
