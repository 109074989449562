import VuexPersistence from "vuex-persist";
import products from "./modules/products";
import company from "./modules/companies";
import plans from "./modules/plans";
import ctas from "./modules/ctas";
import assets from "./modules/assets";
import analytics from "./modules/analytics";
import gs1 from "./modules/gs1";
import { surveyBuilderFormComponents as formComponents } from "@authentic-labs/survey-builder";
import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

// app.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage,
});

export type State = {
  cookieOptIn: boolean;
  auth: { login: boolean; user: object; sessionStart: Date };
  preview: string;
  test: { login: boolean; user: object; sessionStart: Date };
};

export const key: InjectionKey<Store<State>> = Symbol();

// export default new Vuex.Store<State>({
export const store = createStore<State>({
  modules: {
    products,
    company,
    plans,
    ctas,
    assets,
    // @ts-ignore //TODO watch this getting error for getter and State incompatibilities but it works and the module is just like products, company, etc!
    formComponents,
    analytics,
    gs1
  },
  state: {
    cookieOptIn: false,
    auth: {
      login: false,
      user: {},
      sessionStart: new Date(),
    },
    test: {
      login: false,
      user: {},
      sessionStart: new Date(),
    },
    preview: "company",
  },
  getters: {
    isLoggedIn(state) {
      return state.auth.login;
    },
    test(state) {
      return state.auth.user;
    },
    authUser(state) {
      return state.auth.user;
    },
    sessionStart(state) {
      return state.auth.sessionStart;
    },
    preview(state) {
      return state.preview;
    },
  },
  mutations: {
    updateOptInState(state, optInOrOut: boolean) {
      state.cookieOptIn = optInOrOut;
    },
    login(state, status) {
      state.auth.login = status;
      state.auth.user = {};
    },
    setSessionStart(state, sessionStart) {
      state.auth.sessionStart = sessionStart;
    },
    authUser(state, user) {
      state.auth.user = user;
    },
    updatePreview(state: State, preview: string) {
      state.preview = preview;
    },
  },
  plugins: [vuexLocalStorage.plugin],
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
