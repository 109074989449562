<template>
  <b-container>
    <b-row>
      <b-col class="">
        <b-card class="text-center px-4" :class="monthlyClass" no-body>
          <b-card-text class="frequency mt-2" :class="visible"
            >Billed Monthly</b-card-text
          >
          <b-row>
            <!-- <b-col v-if="subscriptionType.name !== 'basic'" > -->
            <b-col>
              <span class="dollar">$</span>
              <span class="subscription-price">{{ pricing }}</span>
            </b-col>
            <!-- <b-col v-else>
              <span class="subscription-price pl-0">Free</span>
            </b-col> -->
          </b-row>
          <b-card-text class="mb-2" :class="visible">USD / Month</b-card-text>
          <a href="#" class="stretched-link" @click="submit('monthly')"></a>
        </b-card>
      </b-col>

      <!-- <b-col>
        <b-card class="text-center" :class="yearlyClass" no-body>
          <b-card-text class="frequency mt-2">Billed Yearly</b-card-text>
          <b-row>
            <b-col>
              <span class="dollar">$</span>
              <span class="subscription-price">{{ pricing.yearly }}</span>
            </b-col>
          </b-row>
          <b-card-text class="mb-2">USD / Year</b-card-text>
          <a href="#" class="stretched-link" @click="submit('yearly')"></a>
        </b-card>
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { getPlans } from "../../services/PaymentService";
import Plan from "../../models/Plan";

@Component
export default class PaymentChoices extends Vue {
  @Prop()
  subscriptionType!: Plan;

  @Prop()
  upgrade!: boolean;

  get pricing() {
    return this.subscriptionType.price;
  }

  get visible() {
    // if(this.subscriptionType.name === 'basic'){
    //   return "hide-text"
    // }
    return "";
  }

  //   get plans() {
  //       return this.$store.getters.plans;
  //   }

  get monthlyClass(): string {
    return "subscription-" + this.subscriptionType.name;
  }
  get yearlyClass(): string {
    return "subscription-" + this.subscriptionType.name + "-year";
  }

  //TODO db table for this information!
  signaturePrice = { monthly: "39", yearly: "444" };
  proPrice = { monthly: "99", yearly: "1128" };

  // async mounted() {
  // console.log(this.subscriptionType, '...subscription type')
  //console.log(this.subscriptionType[0].price, '...new payment choice moiunted')
  // const response = await getPlans();
  // console.log(response)
  // }

  submit(freq: string) {
    // this.emitter.emit("welcome-modal-close");
    this.emitter.emit("subscription-modal-close");
    this.emitter.emit("upgrade-premium-close");

    // this.emitter.emit("payment-modal", this.subscriptionType);
    this.upgrade
      ? this.emitter.emit("payment-modal", {planType: this.subscriptionType, upgrade: true})
      : this.emitter.emit("payment-modal", {planType: this.subscriptionType, upgrade: false});

    //let price = "";
    //     if (this.subscriptionType === "signature") {
    //       freq === "yearly"
    //         ? (price = this.signaturePrice.yearly)
    //         : (price = this.signaturePrice.monthly);
    //     } else {
    //       freq === "yearly"
    //         ? (price = this.proPrice.yearly)
    //         : (price = this.proPrice.monthly);
    //     }
    //     this.emitter.emit("welcome-modal-close");
    //     this.emitter.emit("subscription-modal-close");
    //     this.emitter.emit("upgrade-pro-close");
    //     this.upgrade
    //       ? this.emitter.emit(
    //           "payment-modal",
    //           this.subscriptionType,
    //           freq,
    //           price,
    //           "upgrade"
    //         )
    //       : this.emitter.emit(
    //           "payment-modal",
    //           this.subscriptionType,
    //           freq,
    //           price
    //         );
  }
}
</script>

<style scoped>
.frequency {
  margin-bottom: 0.1rem;
}

.hide-text {
  visibility: hidden;
}

.subscription-basic {
  border-radius: 0.75rem;
  border-color: #14a899;
  /* white-space: nowrap; */
  transition: 0.25s;
  border-width: 2px;
  height: 125px;
}
.subscription-basic:hover {
  border-radius: 0.75rem;
  border-color: #d5f9de;
  white-space: nowrap;
  color: #074f57;
  background-color: #d5f9de;
  border-width: 2px;
}
/* .subscription-signature-year {
  border-radius: 0.75rem;
  border-color: #14a899;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-signature-year:hover {
  border-radius: 0.75rem;
  border-color: #14a899;
  color: white;
  background-color: #14a899;
  border-width: 2px;
} */

.subscription-pro {
  border-radius: 0.75rem;
  border-color: #074f57;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-pro:hover {
  border-radius: 0.75rem;
  border-color: #14a899;
  color: white;
  background-color: #14a899;
  border-width: 2px;
}
/* .subscription-pro-year {
  border-radius: 0.75rem;
  border-color: #074f57;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-pro-year:hover {
  border-radius: 0.75rem;
  border-color: #074f57;
  color: white;
  background-color: #074f57;
  border-width: 2px;
} */

.subscription-business {
  border-radius: 0.75rem;
  border-color: #074f57;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-business:hover {
  border-radius: 0.75rem;
  border-color: #074f57;
  color: white;
  background-color: #074f57;
  border-width: 2px;
}

.subscription-price {
  font-size: 36px;
  font-family: "proximanova-medium", sans-serif;
  padding-right: 0px;
  padding-left: 0.8rem;
}
.subscription-price-text {
  font-size: 20px;
  font-family: "proximanova-regular", sans-serif;
}
.dollar {
  font-size: 1.25rem;
  vertical-align: top;
  position: absolute;
  top: 0.2rem;
  /* padding-left: 3rem; */
}
</style>
