<template>
  <authentic-modal
    ref="paymentModal"
    id="payment-modal"
    :on-submit="submit"
    :on-cancel="cancel"
    :size="modalSize"
    :title="modalTitle"
    :hideFooter="true"
  >
    <b-container class="pb-1">
      <b-row>
        <b-col class="col-12 col-lg-6 px-0 px-sm-4 px-lg-0">
          <b-row>
            <b-col>
              <BasicPlan
                v-if="subscriptionName === 'basic'"
                :show-submit="false"
                :modal-type="'billing'"
              />
              <ProPlan
                v-if="subscriptionName === 'pro'"
                :show-submit="false"
                :modal-type="'billing'"
              />
              <BusinessPlan
                v-if="subscriptionName === 'business'"
                :show-submit="false"
                :modal-type="'billing'"
              />
              <EnterprisePlan
                v-if="subscriptionName === 'enterprise'"
                :show-submit="false"
                :modal-type="'billing'"
              />
            </b-col>
          </b-row>
          <b-row class="pl-4 pr-3 pr-lg-0">
            <b-card-text
              v-if="subscriptionName === 'basic'"
              class="text-muted billing-footnotes"
              >* Basic Listing categories include: Environment, Supply Chain,
              Citizenship, Diversity & Equity and Product Safety
            </b-card-text>

            <b-card-text
              v-if="subscriptionName === 'business'"
              class="text-muted billing-footnotes mb-1"
              >** Premium categories: Social Media Connect, Authorized
              Retailers, Promo Codes & Discounts, Careers & Recruitment, Product
              Registration, Topical, Leadership, News, and more.
            </b-card-text>

            <b-card-text
              class="text-muted billing-footnotes"
              v-if="subscriptionName === 'business'"
              >*** Serialized codes for product authentication available on the
              Pro and Business plans at an additional, but extremely reasonable,
              cost per product.
            </b-card-text>
          </b-row>
        </b-col>
        <b-col class="px-2">
          <BillingDetails
            :subscription-type="subscriptionName"
            :payment-frequency="paymentFrequency"
            :subscription-change="subscriptionChange"
          />
        </b-col>
      </b-row>
    </b-container>
  </authentic-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-facing-decorator";
import AuthenticModal from "../shared/AuthenticModal.vue";
import BillingDetails from "../shared/BillingDetails.vue";

import BasicPlan from "../shared/plans/Basic.vue";
import ProPlan from "../shared/plans/Pro.vue";
import BusinessPlan from "../shared/plans/Business.vue";
import EnterprisePlan from "../shared/plans/Enterprise.vue";

import PaymentMethod from "../../models/PaymentMethod";
import Plan from "../../models/Plan";
import User from "../../models/User";

@Component({
  components: {
    AuthenticModal,
    BillingDetails,
    BasicPlan,
    ProPlan,
    BusinessPlan,
    EnterprisePlan,
  },
})
export default class PaymentModal extends Vue {
  @Ref()
  paymentModal!: AuthenticModal;

  get user(): User {
    return this.$store.getters.authUser;
  }

  modalTitle = "Billing";
  country = "";
  region = "";
  modalSize = "lg";
  subscriptionName = "";
  paymentFrequency = "";
  price = "";
  defaultPaymentMethod?: Partial<PaymentMethod> | null = {};
  refresh = false;
  subscriptionChange = false;
  subscriptionType: Partial<Plan> = {};

  created() {
    this.emitter.on(
      "payment-modal",
      async (paymentObj: {planType: Plan, upgrade: boolean}) => {
        this.subscriptionType = paymentObj.planType;
        this.subscriptionName = paymentObj.planType.name;
        this.paymentFrequency = paymentObj.planType.interval;
        this.price = paymentObj.planType.price.toString();
        paymentObj.upgrade ? (this.refresh = true) : (this.refresh = false);
        this.subscriptionChange = this.refresh;
        this.paymentModal.show();
      }
    );
    this.emitter.on("close-payment-modal", async () => {
      if (this.refresh) {
        this.emitter.emit("billing-summary", "refresh");
      }
      this.paymentModal.hide();
    });
  }

  cancel() {
    this.paymentModal.hide();
  }

  submit() {
    this.paymentModal.hide();
  }

  beforeUnmount() {
    this.emitter.off("payment-modal");
    this.emitter.off("close-payment-modal");
  }
}
</script>

<style scoped>
.icon.bi {
  color: #3679e8;
}

.billing-footnotes {
  font-family: "proximanova-regular", sans-serif;
  font-size: 12px;
}
</style>
