<template>
  <b-container class="pb-4 px-4">
    <b-row no-gutters>
      <b-col>
        <h5 class="current">Add a new card</h5>
      </b-col></b-row
    >
    <billing-details :on-cancel="summary" :card-action="'save'" :newCard="true">
      <template v-slot:billing-title>
        <b-card-text class="mb-2"></b-card-text>
      </template>
    </billing-details>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import BillingDetails from "../shared/BillingDetails.vue";

@Component({
  components: {
    BillingDetails,
  },
})
export default class BillingAddPayment extends Vue {
  summary() {
    this.emitter.emit("billing-summary");
  }
}
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
  /* padding-left: 2rem; */
}

.pay-card {
  font-family: "proximanova-medium", sans-serif;
  font-size: 1px;
}

h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}
</style>
