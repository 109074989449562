<template>
  <b-container class="pb-2 px-4 container-fluid d-flex h-100 flex-column">
    <b-row no-gutters>
      <b-col>
        <h5 class="current">Current plan</h5>
      </b-col></b-row>
    <b-row class="align-items-center">
      <b-col>
        <b-row no-gutters><b-card-text class="subscription-plan">{{
            updateBillingDetails.subscriptionName
            }}</b-card-text></b-row>
        <b-row class="pb-0" no-gutters>
          <b-card-text class="subscription-price">${{ updateBillingDetails.planPrice }} /
            {{ updateBillingDetails.paymentInterval }}</b-card-text>
        </b-row>
        <b-row no-gutters>
          <b-card-text class="subscription-renewal">Your plan renews on
            {{ updateBillingDetails.renewalDate }}</b-card-text>
        </b-row>
      </b-col>
      <b-col class="text-right update-quantity">
        <b-button pill variant="primary" size="sm" class="mx-2" @click="updateQuantity">Update product
          quantity</b-button>
      </b-col>
    </b-row>

    <b-row no-gutters class="pt-4">
      <b-col>
        <h5 class="current">Plans available</h5>
      </b-col></b-row>

    <b-row class="pt-4 align-items-center current" v-if="showPlans && basic">
      <b-col>
        <b-row no-gutters>
          <b-col class="col-4">
            <b-card-text class="subscription-plan pr-4">{{
              $filters.capitalizeFirst(basic.name as string)
              }}</b-card-text>
          </b-col>
          <b-col>
            <!-- <b-card-text class="subscription-price" v-if="displayFreq === 'yearly'"
            >${{ plans.pro.yearly }} / Year</b-card-text
          > -->
            <b-card-text class="subscription-price" v-if="displayFreq === 'monthly'">${{ basic.price }} /
              Month</b-card-text>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right col-auto select-button-col">
        <b-button v-if="updateBillingDetails.subscriptionName !== 'Basic'" pill size="sm" variant="primary"
          @click="upgradePlan('basic')" class="select-button">Select</b-button>
      </b-col>
    </b-row>

    <b-row class="pt-3 align-items-center current" v-if="showPlans && pro">
      <b-col>
        <b-row no-gutters>
          <b-col class="col-4">
            <b-card-text class="subscription-plan pr-4">{{ $filters.capitalizeFirst(pro.name as string) }}</b-card-text>
          </b-col>
          <b-col>
            <!-- <b-card-text class="subscription-price" v-if="displayFreq === 'yearly'"
            >${{ plans.pro.yearly }} / Year</b-card-text
          > -->
            <b-card-text class="subscription-price" v-if="displayFreq === 'monthly'">${{ pro.price }} /
              Month</b-card-text>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right col-auto select-button-col">
        <b-button pill size="sm" variant="primary" @click="upgradePlan('pro')" class="select-button"
          v-if="updateBillingDetails.subscriptionName !== 'Pro'">Select</b-button>
      </b-col>
    </b-row>

    <b-row class="pt-3 align-items-center current" v-if="showPlans && business">
      <b-col>
        <b-row no-gutters>
          <b-col class="col-4">
            <b-card-text v-if="business" class="subscription-plan pr-4">{{
              $filters.capitalizeFirst(business.name as string)
              }}</b-card-text>
          </b-col>
          <b-col class="col-auto">
            <!-- <b-card-text class="subscription-price" v-if="displayFreq === 'yearly'"
            >${{ plans.pro.yearly }} / Year</b-card-text
          > -->
            <b-card-text class="subscription-price" v-if="displayFreq === 'monthly'">${{ business.price }} /
              Month</b-card-text>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right col-auto select-button-col">
        <b-button pill size="sm" variant="primary" @click="upgradePlan('business')" class="select-button"
          v-if="updateBillingDetails.subscriptionName !== 'Business'">Select</b-button>
      </b-col>
    </b-row>

    <b-row class="flex-grow-1 align-items-end text-right row-bottom py-4">
      <b-col class="align-right px-0">
        <b-button-group>
          <b-button pill variant="primary" size="sm" v-b-tooltip.hover title="Tooltip directive content"
            @click="summary">Back to summary</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import Billing from "../../models/Billing";
import Plan from "../../models/Plan";
import {
  updateAdditionalProducts,
  removeAdditionalProducts,
} from "@/services/PaymentService";

@Component
export default class BillingUpdatePlan extends Vue {
  @Prop()
  billingDetails!: Billing;

  get plans(): Plan[] {
    return this.$store.getters.plans;
  }

  get defaultPayment() {
    //TODO an any no-no create PaymentMethod model
    let paymentMethod: any = [];
    const found = this.billingDetails.paymentMethods.find((element: any) => {
      element.default === true;
      paymentMethod = element;
    });
    return paymentMethod;
  }

  updateBillingDetails: Partial<Billing> = {};
  showPlans = false;
  basic: Partial<Plan> = {};
  pro: Partial<Plan> = {};
  business: Partial<Plan> = {};
  enterprise: Partial<Plan> = {};

  //TODO these need to be put in DB
  // plans = {
  //   signature: { name: "Signature", monthly: "39", yearly: "444" },
  //   pro: { name: "Pro", monthly: "99", yearly: "1128" },
  // };

  freqButtons = [
    { caption: "Monthly", state: false },
    { caption: "Yearly", state: false },
  ];

  displayFreq = "monthly";

  summary() {
    this.emitter.emit("billing-summary");
  }

  selectedFreq(idx: number) {
    this.freqButtons.forEach((b, index) => (b.state = idx === index));
    if (idx === 0) {
      this.displayFreq = "monthly";
    } else {
      this.displayFreq = "yearly";
    }
  }

  created() {
    this.$store.dispatch("getPlans");
  }

  mounted() {
    this.basic = this.plans.filter((plan: Plan) => plan.name === "basic")[0];
    this.pro = this.plans.filter((plan) => plan.name === "pro")[0];
    this.business = this.plans.filter((plan) => plan.name === "business")[0];
    this.enterprise = this.plans.filter(
      (plan) => plan.name === "enterprise"
    )[0];

    this.selectedFreq(0);
    if (this.billingDetails.paymentInterval === "year") {
      this.selectedFreq(1);
    }
    if (
      this.billingDetails.paymentInterval === "month" &&
      this.billingDetails.subscriptionName === "Pro"
    ) {
      this.selectedFreq(1);
    }
    this.setVisiblePlans();
    this.updateBillingDetails = this.billingDetails;
    this.selectedFreq(0); //TODO this overrides all in this function and shows prices/monthly
  }

  setVisiblePlans() {
    // TODO do we want to only show upgrade routes so if user has selected pro then only show business and enterprise
    // TODO or do we show all and disable button with current plan, make green and set text to current. then allow both upgrades
    // TODO and downgrades
    if (
      this.billingDetails.subscriptionName !== "Pro" &&
      this.billingDetails.paymentInterval !== "year"
    ) {
      this.showPlans = true;
    }
    if (
      this.billingDetails.subscriptionName === "Pro" &&
      this.billingDetails.paymentInterval === "month"
    ) {
      this.showPlans = true;
    }
    if (
      this.billingDetails.subscriptionName === "Pro" &&
      this.billingDetails.paymentInterval === "month" &&
      this.billingDetails.additionalProducts > 0
    ) {
      this.showPlans = false;
    }
    if (
      this.billingDetails.subscriptionName === "Signature" &&
      this.billingDetails.paymentInterval === "year"
    ) {
      this.showPlans = true;
    }
    this.showPlans = true; //TODO overrides all the above for now
  }

  async upgradePlan(plan: string) {
    //TODO only one plan to update to if we add additional plans will need to make this more general
    //TODO leaving off here need to update similar to welcome modal so its just a change of the payment-modal emit below
    //TODO and take care of the conditionals before it.
    // let price = "";
    // this.displayFreq === "yearly"
    //   ? (price = this.plans.pro.yearly)
    //   : (price = this.plans.pro.monthly);

    //TODO need to run a check on upgrades and downgrades for additional Products and adjust accordingly
    //TODO e.g.: current plan is Pro with the included 3 products and then 2 "additional products". An upgrade
    //TODO business which has 7 included products requires that the "addition products" cost for 2 with pro
    //TODO be removed. Another question is downgrades - do we allow and how do we handle the products.

    //TODO check addons if less than products allows then call updateAdditionalProducts and remove before setting up new plan

    let numberofProd = 0;
    let newPlan: Partial<Plan> = {};
    switch (plan) {
      case "basic":
        newPlan = this.basic;
        numberofProd = this.basic.productNumber!;
        break;
      case "pro":
        newPlan = this.pro;
        numberofProd = this.pro.productNumber!;
        break;
      case "business":
        newPlan = this.business;
        numberofProd = this.business.productNumber!;
        break;
      case "enterprise":
        newPlan = this.enterprise;
        numberofProd = this.enterprise.productNumber!;
        break;
    }

    //account for additional products first just remove them all
    // const priceId = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT;
    // const paymentDetails = {
    //     paymentMethod: this.defaultPayment.id,
    //     priceId: priceId,
    //     subscriptionType: plan,
    //     currentAdditionalProducts: this.billingDetails.additionalProducts,
    //   };
    // await removeAdditionalProducts(paymentDetails);

    // console.log(this.billingDetails, '...billing details')
    // const currentTotalProducts = this.billingDetails.additionalProducts + this.billingDetails.productNumber
    // if( currentTotalProducts <= numberofProd && this.billingDetails.additionalProducts > 0){
    //   await this.removeAdditionalProducts(0, newPlan.name!)
    // } else if (this.billingDetails.additionalProducts > 0){
    //   const newAdditionalProducts = currentTotalProducts - numberofProd;
    // }
    // const addOns = this.subscription.numberAddons

    this.emitter.emit("payment-modal", {planType: newPlan, upgrade: true});
  }

  async removeAdditionalProducts(count: number, plan: string) {
    const priceId = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT;
    const paymentDetails = {
      paymentMethod: this.defaultPayment.id,
      priceId: priceId,
      quantity: count,
      subscriptionType: plan,
      currentAdditionalProducts: this.billingDetails.additionalProducts,
    };
    const subscribeResponse = await updateAdditionalProducts(paymentDetails);
  }

  updateQuantity() {
    this.emitter.emit("billing-update-quantity", {productList: false, fromArchive: false});
  }
}
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
}

.update-quantity {
  position: absolute;
  right: 0;
  top: 40px;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-plan {
  font-size: 18px;
}

.update-disabled {
  background-color: green;
}

.select-button {
  width: 80px;
}

.select-button-col {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 576px) {
  .subscription-price[data-v-02a0f5e3] {
    font-size: 16px;
  }

  .subscription-plan[data-v-02a0f5e3] {
    font-size: 16px;
  }
}
</style>
