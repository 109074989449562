import Vue, { createApp, h } from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';

// @ts-ignore
import vueCountryRegionSelect from "vue-country-region-select";


import { plugin, defaultConfig } from '@formkit/vue'
import "@formkit/themes/genesis";

import axios from "axios";
// import vueCountryRegionSelect from "vue-country-region-select";
// import formComponents from "@authentic-labs/survey-builder"
import moment from "moment";
import mitt from "mitt";
const emitter = mitt();

// main.js/ts
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import "./assets/scss/main.scss";


axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";


// 
// formComponents(store)

const app = createApp({
  render: () => h(App),
  // created() {
  //   // emitter = this.emitter;
  //   this.emitter.on("alertSuccess", (message: string) => {
  //     this.alertSuccess(message);
  //   });
  //   this.emmitter.on("alertError", (message: string) => {
  //     this.alertError(message);
  //   });
  // },
});

// Vue.use(store)
app.use(store);
app.use(router);
app.use(plugin, defaultConfig);
app.use(vueCountryRegionSelect);

const options: PluginOptions = {
  timeout: 5000,
};
app.use(Toast, options);

app.provide('emitter', emitter);   


// Make BootstrapVue available throughout your project
// app.use(BootstrapVue);
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// app.provide('emitter', emitter)
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$filters = {
  formatDate(value: Date) {
    if (value) {
      return moment(String(value)).format("MM/DD/YYYY");
    }
    return "";
  },
  formatEndDate(value: Date) {
    if (value) {
      if (new Date(value) < new Date()) {
        return "EXPIRED";
      } else {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    }
    return "";
  },
  capitalizeFirst(value: string) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    }
  },
  formatMonth(value: number) {
    if (value) {
      return value.toString().padStart(2, "0");
    }
  },
  formatName(value: string) {
    if (value) {
      const tmpNameArr = value.split(".");
      if (tmpNameArr[tmpNameArr.length - 1] === "css") {
        tmpNameArr.splice(tmpNameArr.length - 1, 1);
        return tmpNameArr.join();
      }
      return value;
    }
  },
};

// app.config.globalProperties.$methods = {
//   alertSuccess(message: string) {
//     this.$bvToast.toast(message, {
//       title: "Success!",
//       autoHideDelay: 10000,
//       variant: "success",
//     });
//   },
//   alertError(message: string) {
//     this.$bvToast.toast(message, {
//       title: "Error!",
//       autoHideDelay: 5000,
//       variant: "danger",
//     });
//   },
// };

// configureCompat({ MODE: 2 });

// Bootstrap-Vue components that are used in form generation
import {
  BIcon,
  BTooltip,
  BContainer,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButton,
  BCardBody,
  BNav,
  BCard,
  BCardHeader,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BToast,
  BOverlay,
  BFormFile
  // VBToggle
} from "bootstrap-vue";
Vue.component("b-container", BContainer);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-form-checkbox-group", BFormCheckboxGroup);
Vue.component("b-button", BButton);
Vue.component("b-card", BCard);
Vue.component("b-card-body", BCardBody);
Vue.component("b-nav", BNav);
Vue.component("b-card-header", BCardHeader);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-form-select", BFormSelect);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-input-group-append", BInputGroupAppend);
Vue.component("b-form-datepicker", BFormDatepicker);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("icon", BIcon);
Vue.component("b-dropdown", BDropdown);
Vue.component("b-dropdown-item", BDropdownItem);
Vue.component("b-input", BFormInput);
Vue.component("b-form-spinbutton", BFormSpinbutton);
Vue.component("b-tooltip", BTooltip);
Vue.component("b-toast", BToast);
Vue.component("b-overlay", BOverlay)
Vue.component("b-form-file", BFormFile)
// Vue.component("b-toggle", VBToggle);
// End package settings

app.mount("#app");
