<template>
  <authentic-modal
    ref="assetGalleryModal"
    id="asset-gallery-modal"
    :on-submit="submit"
    :on-cancel="cancel"
    :size="'xl'"
    :hideFooter="true"
    :no-close-on-backdrop="true"
  >
    <div>
      <Gallery :type="galleryType" />
    </div>

    <b-button-group class="pr-4 fix-buttons">
      <b-button
        pill
        variant="outline-secondary"
        size="sm"
        class="ml-2"
        @click.prevent="cancel"
        >Cancel</b-button
      >
      <b-button
        pill
        variant="primary"
        size="sm"
        class="ml-2"
        :disabled="disableSave"
        @click.prevent="submit"
        >Select</b-button
      >
    </b-button-group>
    <!-- </template> -->
  </authentic-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref, Prop } from "vue-facing-decorator";
import AuthenticModal from "../AuthenticModal.vue";
import Gallery from "../assetlibrary/Gallery.vue";

@Component({
  components: {
    AuthenticModal,
    Gallery,
  },
})
export default class AssetGalleryModal extends Vue {
  @Ref()
  assetGalleryModal!: AuthenticModal;
  // @Prop()
  // type!: string;
  @Prop()
  mediaSelected?: () => void;

  disableSave = false;
  galleryType = "";

  mounted() {
    this.emitter.on("media-select", async (type: string) => {
      this.galleryType = type;
      // if(type){
      //   this.galleryType = type;
      // }
      this.$store.commit("setSelectedGalleryAsset", {});
      this.assetGalleryModal.show();
    });
  }

  //  okClicked() {
  //   if (this.onSubmit) {
  //     this.onSubmit();
  //   }
  // }

  // onSubmit(){
  //   if (this.mediaSelected) {
  //     this.mediaSelected()
  //   }
  // }

  submit() {
    if (this.mediaSelected) {
      this.mediaSelected();
    }
    this.assetGalleryModal.hide();
  }
  cancel() {
    this.assetGalleryModal.hide();
  }
  beforeUnmount() {
    this.emitter.off("media-select");
  }
}
</script>

<style scoped>
.fix-buttons {
  position: absolute;
  right: 20px;
  bottom: 25px;
}
:deep header #cta-modal.modal-header {
  display: none !important;
}

:deep .modal-footer {
  padding: 0;
}

:deep .modal-dialog > .modal-content > .modal-header {
  background-color: #da2228 !important;
  color: white;
}
</style>
