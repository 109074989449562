/**
 * Service for user API calls
 */
import { apiClient } from "./Api";
import User, { UserDTO } from "../models/User";

export async function forgotPassword(payLoad: object) {
  const { statusText: response } = await apiClient.post<string>(
    "/api/user/password-reset",
    payLoad
  );
  return response;
}

export async function profile(id: number, user: User) {
  const userDto = UserDTO.create(user);
  const { statusText: response } = await apiClient.put<string>(
    `/api/user/${id}`,
    userDto
  );
  return response;
}

export async function addUser(payLoad: object) {
  const response = await apiClient.post("/adduser", payLoad);
}

export async function updateUser(companyId: number, id: number, user: User) {
  const userDto = UserDTO.create(user);
  const { statusText: response } = await apiClient.put<string>(
    `/api/companies/${companyId}/users/${id}`,
    userDto
  );
  return response;
}
