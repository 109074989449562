export default interface User {
  id: number;
  email: string;
  companyId: number;
  phone?: string;
  address1?: string;
  address2?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  avatar: string;
  name: string;
  status?: string;
  role?: string;
}

//TODO modify based on final form of User profile
//TODO Do not have address for individual users
//TODO most of the members might need to be removed
export class UserDTO implements User {
  id: number;
  email: string;
  companyId: number;
  role?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  avatar: string;
  name: string;
  status: string;

  constructor(
    id = -1,
    email = "",
    avatar = "",
    name = "",
    companyId = -1,
    role?: string,
    status = "",
    phone?: string
  ) {
    this.id = id;
    this.email = email;
    this.avatar = avatar;
    this.name = name;
    this.companyId = companyId;
    this.role = role;
    (this.status = status), (this.phone = phone);
  }

  static create(user: User) {
    return new UserDTO(
      user.id,
      user.email,
      user.avatar,
      user.name,
      user.companyId,
      user.role,
      user.status,
      user.phone
    );
  }
}

export const NoUser = (): Partial<User> => ({ id: -1 });
