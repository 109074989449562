<template>
  <b-container class="pb-2 px-4 container-fluid d-flex h-100 flex-column">
    <b-row no-gutters>
      <b-col>
        <h5 class="current">Current plan</h5>
      </b-col></b-row
    >
    <b-row class="align-items-center">
      <b-col>
        <b-row no-gutters
          ><b-card-text class="subscription-plan">{{
            billingDetails.subscriptionName
          }}</b-card-text></b-row
        >
        <b-row class="pb-0" no-gutters>
          <b-card-text class="subscription-price"
            >${{ billingDetails.planPrice }} /
            {{ billingDetails.paymentInterval }}</b-card-text
          >
        </b-row>
        <b-row no-gutters>
          <b-card-text class="subscription-renewal"
            >Your plan renews on {{ billingDetails.renewalDate }}</b-card-text
          >
        </b-row>
      </b-col>
      <!-- <b-col v-if="billingDetails.subscriptionName === 'Pro'" class="text-right"> -->
      <b-col class="text-right">
        <b-button
          pill
          variant="outline-danger"
          size="sm"
          class="mx-2"
          @click="cancelPlan"
          :disabled="disableCancel"
          >Cancel current plan</b-button
        >
      </b-col>
    </b-row>

    <b-row class="pt-4" no-gutters>
      <b-col>
        <h5 class="current">Cancellation</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card-text>
          <span class="warning-text">Warning:</span> Selecting Cancel current
          plan above will terminate your current plan with Authentic Labs.
          Access to the Real&reg; Brand Dashboard will still be available until
          your renewal date after that you will not be able to edit or publish
          content. You will be able to resume your plan anytime up to the
          cancellation date.</b-card-text
        >
      </b-col>
    </b-row>

    <b-row class="flex-grow-1 align-items-end text-right row-bottom pb-3">
      <b-col class="align-right">
        <b-button-group>
          <b-button
            pill
            variant="primary"
            size="sm"
            v-b-tooltip.hover
            title="Tooltip directive content"
            @click="summary"
            >Back to summary</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { Company } from "@authentic-labs/vue-component-library";
import Billing from "../../models/Billing";
import { cancelPlan } from "../../services/PaymentService";
import { updateCompanyAdmin } from "../../services/AdminService";
import { useToast } from "vue-toastification";

@Component
export default class BillingCancelPlan extends Vue {
  @Prop()
  billingDetails!: Billing;

  get company(): Company {
    return this.$store.getters.company;
  }

  disableCancel = false;
  toast = useToast();

  summary() {
    this.emitter.emit("billing-summary");
  }

  async cancelPlan() {
    this.disableCancel = true;
    const response = await cancelPlan();
    if (response === "success") {
      this.toast.success("Your plan was successfully cancelled");
      this.emitter.emit("billing-summary", "refresh");
      this.disableCancel = false;
    } else {
      this.toast.error("A problem occurred when cancelling your account");
    }
  }
}
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}
.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-plan {
  font-size: 18px;
}

.warning-text {
  color: red;
  font-size: 20px;
  font-family: "proximanova-bold", sans-serif;
}
</style>
