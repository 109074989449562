/**
 * Service for company API calls
 */
import { apiClient } from "./Api";
import { Company } from "@authentic-labs/vue-component-library";
import ContentSection from "../models/ContentSection";
import User from "../models/User";

export async function createUser(companyId: number, payLoad: object) {
  const { data: response } = await apiClient.post(
    `/api/companies/${companyId}/users/invite`,
    payLoad
  );
  return response.status;
}

export async function fetchCompaniesAdmin(payLoad: object) {
  const { data: companyResponse } = await apiClient.get(
    "/api/admin/companies",
    { params: payLoad }
  );
  return {
    resultsReturned: companyResponse.companies.total,
    currentPage: companyResponse.companies.current_page,
    companies: companyResponse.companies.data,
    activeCompanyCount: companyResponse.activeCompanyCount,
    companyCount: companyResponse.companyCount,
  };
}

//TODO rename this and move to adminServices
export async function updateCompanyAdmin(companyId: number, payLoad: object) {
  const { data: company } = await apiClient.put(
    `/api/admin/companies/${companyId}`,
    payLoad
  );
  return company;
}

export async function fetchCompanyAdmin(companyId: number) {
  const { data: company } = await apiClient.get(
    `/api/admin/companies/${companyId}`
  );
  return {
    company: new Company(company),
    customSections: company.customSections,
    contentSections: company.contentSection,
    paidSections: company.contentSection.filter(
      (section: ContentSection) => section.pivot!.paid
    ),
    stats: company.stats,
  };
}

export async function createCompanyAdmin(payLoad: object) {
  const { data: response } = await apiClient.post(
    "/api/admin/companies",
    payLoad
  );
  return response;
}

export async function updateTags(companyId: number, payLoad: object) {
  const { data: response } = await apiClient.put(
    `api/companies/${companyId}/updateTags`,
    payLoad
  );
  return response;
}

export async function getTags(companyId: number) {
  const { data: response } = await apiClient.get(
    `api/companies/${companyId}/getTags`
  );
  return response;
}

export async function getContentSectionsAdmin() {
  const { data: response } = await apiClient.get("api/admin/contentSections");
  return response.contentSection;
  // console.log(response.contentSection)
}

export async function createContentSectionAdmin(payLoad: object) {
  const { data: response } = await apiClient.post(
    `api/admin/contentSections`,
    payLoad
  );
  return response;
}

export async function updateContentSectionAdmin(
  sectionId: number,
  payLoad: object
) {
  const { data: response } = await apiClient.put(
    `api/admin/contentSections/${sectionId}`,
    payLoad
  );
  return response;
}

export async function updateContentDisplayOrderAdmin(payLoad: object) {
  const { data: response } = await apiClient.put(
    `api/admin/contentSections/updateDisplayOrder`,
    payLoad
  );
  return response;
}

export async function deleteContentSectionAdmin(sectionId: number) {
  const { data: response } = await apiClient.delete(
    `api/admin/contentSections/${sectionId}`
  );
  return response;
}

export async function createApiTokenAdmin(payLoad: object) {
  const { data: response } = await apiClient.post(
    `api/admin/apiTokens`,
    payLoad
  );
  return response;
}

export async function fetchApiTokenAdmin(companyId: number) {
  const { data: response } = await apiClient.get(
    `api/admin/apiTokens/${companyId}`
  );
  return response;
}

export async function deleteApiTokenAdmin(companyId: number) {
  const { data: response } = await apiClient.delete(
    `api/admin/apiTokens/${companyId}`
  );
  return response;
}
