<template>
  <div class="fluid d-flex flex-column fill">
    <NavigationBar />
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
    <!-- @vue-ignore -->
    <b-toast ref="toast" :title="toastTitle" :autoHideDelay="5000" :variant="toastVariant">{{ toastMsg }}</b-toast> 
  </div>
</template>

<script lang="ts">
import NavigationBar from "./views/NavigationBar.vue";
import { Component, Vue, Ref } from "vue-facing-decorator";

@Component({
  components: { NavigationBar },
})
export default class App extends Vue {
  @Ref()
  readonly toast!: any;

  toastMsg = "";
  toastTitle = "";
  toastVariant = "";
  // created() {
  //   this.emitter.on("alertSuccess", (message: string) => {
  //     this.alertSuccess(message);
  //   });
  //   this.emitter.on("alertError", (message: string) => {
  //     this.alertError(message);
  //   });
  // }

  // alertSuccess(message: string) {
  //   this.toastMsg = message;
  //   this.toastTitle = "Success!";
  //   this.toastVariant = "success";
  //   this.toast.show()
  // }
  // alertError(message: string) {
  //   this.toastMsg = message;
  //   this.toastTitle = "Error!";
  //   this.toastVariant = "danger";
  //   this.toast.show()
  // }
}
</script>

<style scoped>
.fill {
  height: 100vh;
  min-height: 100%;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter-from, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
