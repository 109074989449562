<template>
  <b-container style="height: 715px">
    <b-card id="asset-gallery" class="asset-gallery">
      <b-card-header
        class="pt-0"
        header-border-variant="0"
        header-bg-variant="transparent"
      >
        <b-row class="align-items-center">
          <b-col class="pl-1 col-auto">
            <div class="text-left header">{{ galleryTitle }}</div>
          </b-col>
          <b-col v-if="type === 'both'" class="align-self-end pb-1">
            <b-form-group label-cols="auto" label="Type:" class="pb-0 mb-0">
              <b-form-radio-group
                v-model="headerTypeSelected"
                :options="options"
                name="radio-inline"
                @change="changeMediaType"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <div class="gallery mt-3">
        <div
          v-for="(media, index) in mediaAssets"
          :key="index"
          class="gallery-panel"
          :class="media.filesId === selectedId ? 'selected' : 'unselected'"
          @click="
            selectedImage(
              media.filesId,
              media.fileUrl,
              media.type,
              media.caption,
              media.previewId
            )
          "
        >
          <div v-if="media.filesId === selectedId" class="selected-icon">
            <img :src="getSelectedIcon()" />
          </div>
          <b-img
            :src="media.fileUrl"
            width="140"
            v-if="media.type === 'image'"
          />
          <video
            :src="media.fileUrl"
            width="140"
            v-if="media.type === 'movie'"
            onmouseover="this.play()"
            onmouseout="this.pause();"
          />
          <b-img
            v-if="media.type === 'pdf' && media.previewUrl != ''"
            :src="media.previewUrl ? media.previewUrl : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'"
            width="140"
            :key="updatePreviewPdfImages"
            alt=""
          />
        </div>
      </div>
    </b-card>
    <b-form-row v-if="type === 'movie'" class="pl-3">
      <b-col class="col-5">
        <b-form-group
          label-for="embed-link"
          label="Or embed external URL"
          content-cols="12"
          class="embed-class"
        >
          <b-form-input
            ref="embedUrl"
            id="embed-link"
            v-model="embedUrl"
            placeholder="Embed URL"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from "vue-facing-decorator";
import { fetchAssets, fetchFile } from "@/services/FileService";
import MediaAsset from "./MediaAsset.vue";
import Asset from "@/models/Asset";

@Component({
  components: {
    MediaAsset,
  },
})
export default class Gallery extends Vue {
  @Ref()
  embedInput!: HTMLInputElement;
  @Prop()
  type!: string;

  get companyId(): number {
    return this.$store.getters.company.companyId;
  }

  get galleryTitle(): string {
    if (this.type === "image") {
      return "Select Image";
    }
    if (this.type === "movie") {
      return "Select Video";
    }
    if (this.type === "both") {
      return "Select Header Media";
    }
    if (this.type === "pdf") {
      return "Select PDF";
    }
    return "";
  }

  headerTypeSelected = "image";
  options = [
    { text: "image", value: "image" },
    { text: "video", value: "movie" },
  ];

  statusSelected = "all";
  mediaAssets: any[] = [];
  selectedId = -1;
  embedUrl = "";
  isHovered = -1;
  previewUrl = "";
  updatePreviewPdfImages = 0;


  mounted() {
    this.mediaAssets = [];
    this.getAssets();
  }

  changeMediaType() {
    this.getAssets();
  }

  getSelectedIcon(): string {
    return new URL("/src/assets/images/app/selectedasset.svg", import.meta.url).href;
  }

  async handleHover(index: number, media: any) {
    this.isHovered = index;
  }

  // mediaTest = [
  //   {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 1
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 2
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 3
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 4
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 5
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 6
  //   },
  //   {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 7
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 8
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 9
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 10
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 11
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 12
  //   },
  //   {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 13
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 14
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 15
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 16
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 17
  //   },
  //    {
  //     fileUrl:
  //       "https://authentic-labs-staging-assets.s3.us-east-2.amazonaws.com/1/ef2b1c78-c503-4bc1-b89d-5d51211ae86c.mp4",
  //     filesId: 18
  //   },
  // ];

  async getAssets() {
    let assetType = this.type;
    if (this.type === "both") {
      assetType = this.headerTypeSelected;
    }
    const fetchResponse = await fetchAssets({
      companyId: this.companyId,
      params: { status: "active", type: assetType, size: 1000 },
    });
    this.mediaAssets = fetchResponse.assets;
    if (assetType === "pdf") {
      this.getPdfPreviewUrls();
    }

    // this.mediaAssets = this.mediaTest
    // this.mediaAssets = fetchResponse.assets.filter(
    //   (x: { type: string }) => x.type === "image"
    // );
    // this.mediaAssets = fetchResponse.assets.filter(
    //   (x: { type: string }) => x.type === "movie"
    // );
  }

  // TODO maybe make a promise, lets see how it feels first
  getPdfPreviewUrls() {
    this.mediaAssets.forEach(
      async (x: { previewId: number; previewUrl: string }) => {
        if (x.previewId) {
          const previewFile = await fetchFile(x.previewId);
          x.previewUrl = previewFile.fileUrl;
        } else {
          x.previewUrl = new URL("/src/assets/images/app/pdfThumbnail.svg", import.meta.url).href;
          // x.previewUrl = require(`@/assets/images/app/pdfThumbnail.svg`);
        }
        this.updatePreviewPdfImages++;
      }
    );
  }

  selectedImage(
    fileId: number,
    mediaUrl: string,
    type: string,
    caption: string,
    previewId: number
  ) {
    this.selectedId = fileId;
    this.$store.commit("setSelectedGalleryAsset", {
      fileUrl: mediaUrl,
      filesId: fileId,
      type: type,
      caption: caption,
      previewId: previewId,
    });
  }
}
</script>

<style scoped>
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 1rem;
  max-width: 80rem;
  overflow-y: auto;
  height: 420px;
  padding-top: 10px;
}

.selected-icon {
  position: absolute;
  top: -12px;
  right: -10px;
}

.selected-icon img {
  width: 20px !important;
  height: 20px !important;
}

.selected {
  border: 2px solid #27a8e0;
}
.unselected {
  border: 2px dashed #27a8e0;
}

.gallery-panel {
  width: 144px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.gallery-panel img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

.asset-gallery {
  height: 530px;
  width: 1075px;
  border: 0;
  /* overflow-y: auto; */
}

.embed-class {
  font-family: "proximanova-medium", sans-serif;
  font-size: 1rem;
}

.form-control {
  font-size: 1rem;
}

/* .asset-gallery {
  height: 715px;
  width: 1075px;
  border: 0;
} */

.asset-gallery-details {
  background-color: #f9f9f9;
  height: 100%;
}

.asset-form-height {
  height: 90%;
}

:deep .form-row {
  align-items: center;
}

:deep legend {
  font-family: "proximanova-medium", sans-serif;
  font-size: 1rem;
  margin-top: -2px;
}

:deep label.custom-control-label {
  font-family: "proximanova-medium", sans-serif;
  font-size: 1rem;
}

.header {
  font-family: "proximanova-medium", sans-serif;
  font-size: 2.25rem;
}
</style>
