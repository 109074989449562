<template>
  <b-card border-variant="0" class="plan-card">
    <b-card-header
      header-bg-variant="transparent"
      header-border-variant="0"
      class="plan-header text-center"
      >Enterprise</b-card-header
    >
    <b-card-body
      ><b-card-text
        >Enterprise plan here. CSR sections. No premium (pro) content.
        {{ planDetails.productNumber }} product,
        {{ planDetails.productSections }} sections
      </b-card-text></b-card-body
    >
  </b-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import Plan from "../../../models/Plan";

@Component
export default class EnterprisePlan extends Vue {
  @Prop()
  planDetails!: Plan;
}
</script>

<style scoped>
.plan-card {
  border-radius: 0.75rem;
}
.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}
</style>
