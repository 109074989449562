/**
 * Vuex store for ctas
 */
// import { Cta } from "@authentic-labs/survey-builder/dist/models"

import Asset from "@/models/Asset";

type AssetState = {
  selectedCssAsset: { name: string; css: string; type: string; status: string };
  asset: {
    name: string;
    css: string;
    type: string;
    status: string;
    assetName: string;
    media: File;
    thumb: File;
  };
  caption: string;
  selectedGalleryAsset: {
    fileUrl: string;
    filesId: number;
    type: string;
    caption: string;
    previewId: number;
  };
  disabled: boolean;
  //   name: string;
  //   cssContent: string
};

const initialState = {
  asset: {},
  selectedCssAsset: {},
  caption: "",
  selectedGalleryAsset: {},
  disabled: false,
};

export default {
  state: initialState,

  getters: {
    // selectedAsset(state: AssetState) {
    //   return state.selectedAsset;
    // },
    asset(state: AssetState) {
      return state.asset;
    },
    assetName(state: AssetState) {
      return state.asset.name;
    },
    selectedCssContent(state: AssetState) {
      return state.selectedCssAsset.css;
    },
    cssContent(state: AssetState) {
      return state.asset.css;
    },
    selectedAsset(state: AssetState) {
      return state.selectedCssAsset;
    },
    assetCaption(state: AssetState) {
      return state.caption;
    },
    selectedGalleryAsset(state: AssetState) {
      return state.selectedGalleryAsset;
    },
    disabled(state: AssetState) {
      return state.disabled;
    },
  },

  mutations: {
    setAsset(
      state: AssetState,
      payload: {
        name: string;
        css: string;
        type: string;
        status: string;
        assetName: string;
        media: File;
        thumb: File;
      }
    ) {
      state.asset = payload;
    },
    setAssetCaption(state: AssetState, payload: string) {
      state.caption = payload;
    },
    setAssetName(state: AssetState, payload: string) {
      state.asset.name = payload;
    },
    setAssetStatus(state: AssetState, payload: string) {
      state.asset.status = payload;
    },
    setCssContent(state: AssetState, payload: string) {
      state.asset.css = payload;
    },
    setMediaAsset(state: AssetState, payload: File) {
      state.asset.media = payload;
    },
    setPdfPreviewAsset(state: AssetState, payload: File) {
      state.asset.thumb = payload;
    },
    setSelectedCssAsset(
      state: AssetState,
      selectedAsset: { name: string; css: string; type: string; status: string }
    ) {
      state.selectedCssAsset = selectedAsset;
    },
    setSelectedGalleryAsset(
      state: AssetState,
      payload: {
        fileUrl: string;
        filesId: number;
        type: string;
        caption: string;
        previewId: number;
      }
    ) {
      state.selectedGalleryAsset = payload;
    },
    setDisabled(state: AssetState, payload: boolean) {
      state.disabled = payload;
    },
  },
};
