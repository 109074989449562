import Media from "./Media";
import { Content, Product } from "@authentic-labs/vue-component-library";
export class ProductDTO {
  model: string;
  name: string;
  status: string;
  upcCode: string;
  productId?: number;
  publishDate?: Date;
  unpublishedContent?: Content[];
  publishedContent?: Content[];
  appLogoFile?: Media;
  imageFile?: Media;
  mp4File?: Media;
  webmFile?: Media;
  published?: boolean;
  contentSavedDate?: Date;
  currentHeaderId?: number;
  title?: string;
  constructor(
    model = "",
    name = "",
    upcCode = "",
    status = "",
    appLogoFile?: Media,
    productId?: number,
    publishDate?: Date,
    unpublishedContent?: Content[],
    publishedContent?: Content[],
    published?: boolean,
    contentSavedDate?: Date,
    imageFile?: Media,
    mp4File?: Media,
    webmFile?: Media,
    currentHeaderId?: number,

    title?: string
  ) {
    this.model = model;
    this.name = name;
    this.upcCode = upcCode;
    this.status = status;
    this.appLogoFile = appLogoFile;
    this.publishDate = publishDate;
    this.productId = productId;
    this.unpublishedContent = unpublishedContent;
    this.publishedContent = publishedContent;
    this.published = published;
    this.contentSavedDate = contentSavedDate;
    this.imageFile = imageFile;
    this.mp4File = mp4File;
    this.webmFile = webmFile;
    this.currentHeaderId = currentHeaderId;

    this.title = title;
  }

  static create(product: Partial<Product>) {
    return new ProductDTO(
      product.model,
      product.name,
      product.upcCode,
      product.status,
      product.appLogoFile,
      product.productId,
      product.publishDate,
      product.unpublishedContent,
      product.publishedContent,
      product.published,
      product.contentSavedDate,
      product.imageFile,
      product.mp4File,
      product.webmFile,
      product.currentHeaderId,
      product.title
    );
  }
}
