import { apiClient } from "./Api";
import { AxiosInstance, AxiosResponse } from "axios";
import Asset from "@/models/Asset";

export type FileRecord = {
  uploadUrl: string;
  name: string;
  size: number;
  filesId: number;
  fileUrl: string;
  fileRemoteObjectId: string;
};

export async function getFileUploadURL(
  file: File,
  fileDescription: string,
  status: string,
  caption: string,
  assetName: string,
  companyId: number,
  fileType: string,
  previewId: number | null
): Promise<FileRecord> {
  const response = await apiClient.post<FileRecord>("/api/files", {
    fileName: file.name,
    fileDescription: fileDescription,
    status: status,
    caption: caption,
    assetName: assetName,
    type: fileType,
    previewId: previewId,
    companyId: companyId,
  });
  return response.data;
}

export async function deleteFile(fileId: number): Promise<AxiosResponse> {
  return apiClient.delete("/api/files/" + fileId);
}

// export async function fetchFile(fileId: number): Promise<AxiosResponse> {
//   const response = await apiClient.get("/api/files/" + fileId);
//   return response.data
// }

export async function fetchFile(fileId: number) {
  const response = await apiClient.get("/api/files/" + fileId);
  return response.data;
}

export async function uploadFile(
  file: File,
  status = "inactive",
  fileType = "empty",
  caption = "",
  assetName = "",
  companyId = -1,
  previewId: number | null = null,
  fileDescription = ""
): Promise<FileRecord> {
  const fileRecord = await getFileUploadURL(
    file,
    fileDescription,
    status,
    caption,
    assetName,
    companyId,
    fileType,
    previewId
  );
  try {
    await apiClient.put(fileRecord.uploadUrl, file, {
      headers: {
        "x-al-filename": file.name,
        "Content-Type": file.type,
        "Cache-Control": "no-cache, no-store",
      },
    });
  } catch (error) {
    console.log(error);
  }
  return fileRecord;
}

export async function updateAsset(asset: Asset, file: File) {
  const response = await apiClient.put("/api/files/" + asset.filesId, {
    status: asset.status,
    caption: asset.caption,
    name: asset.name,
    assetName: asset.assetName,
    previewId: asset.previewId,
    companyId: asset.companyId,
  });
  const fileRecord = response.data;
  try {
    await apiClient.put(fileRecord.uploadUrl, file, {
      headers: {
        "x-al-filename": file.name,
        "Content-Type": file.type,
        "Cache-Control": "no-cache, no-store",
      },
    });
  } catch (error) {
    console.log(error);
  }
  return fileRecord;
}

export async function updateProductAsset(
  companyId: number,
  productId: number,
  payLoad: Asset | Asset[]
) {
  const { data: response } = await apiClient.put(
    `api/companies/${companyId}/products/${productId}/updateAsset`,
    payLoad
  );
  return response;
}

export async function getProductAsset(companyId: number, assetId: number) {
  const { data: response } = await apiClient.get(
    `api/companies/${companyId}/assets/${assetId}`
  );
  return response;
}

export async function getImageFromSrc(url: string) {
  const response = await fetch(url);
  const data = await response.blob();
  let contenttype = response.headers.get("Content-Type");
  let img = "";
  if (contenttype) {
    img = contenttype.split("/")[1];
  } else {
    contenttype = "image";
    img = "jpg";
  }
  const metadata = {
    type: contenttype,
  };
  return new File([data], "temp." + img);
}

export async function fetchAssets(payLoad: {
  companyId: number;
  params: object;
}) {
  const { data: response } = await apiClient.get(
    `/api/companies/${payLoad.companyId}/files`,
    { params: payLoad.params }
  );
  return {
    resultsReturned: response.assets.total,
    currentPage: response.assets.current_page,
    assets: response.assets.data,
    assetCount: response.assetCount,
  };
}
