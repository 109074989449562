<template>
  <b-container>
    <b-row>
      <b-col class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <b-row class="h-100">
          <b-col class="px-0">
            <BasicPlan
              :plan-details="basic"
              :show-submit="true"
              class="scale-test"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <b-row>
          <b-col class="px-0">
            <ProPlan
              :plan-details="pro"
              :show-submit="true"
              class="scale-test"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-sm-12 col-lg-8 col-xl-4 mx-lg-auto">
        <b-row>
          <b-col class="px-0">
            <BusinessPlan
              :plan-details="business"
              :show-submit="true"
              class="scale-test"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col class="border  mr-2">
        <b-row>
              <EnterprisePlan :plan-details="enterprise" />
          </b-row>  
            <b-row class="px-3 pb-3 align-items-end row-bottom" v-if="showSubmit">
            <payment-choices-tmp :subscription-type="enterprise">
            </payment-choices-tmp>
          </b-row>
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Vue } from "vue-facing-decorator";
import PaymentChoices from "./PaymentChoices.vue";
import BasicPlan from "../shared/plans/Basic.vue";
import ProPlan from "../shared/plans/Pro.vue";
import BusinessPlan from "../shared/plans/Business.vue";
import EnterprisePlan from "../shared/plans/Enterprise.vue";
import Plan from "../../models/Plan";
import { Component } from "vue-facing-decorator";

@Component({
  components: {
    PaymentChoices,
    BasicPlan,
    ProPlan,
    BusinessPlan,
    EnterprisePlan,
  },
})
export default class RealPlanGroup extends Vue {
  get plans(): Plan[] {
    return this.$store.getters.plans;
  }

  showSubmit = true;
  basic: Partial<Plan> = {};
  pro: Partial<Plan> = {};
  business: Partial<Plan> = {};
  enterprise: Partial<Plan> = {};

  async mounted() {
    this.basic = this.plans.filter((plan: Plan) => plan.name === "basic")[0];
    this.pro = this.plans.filter((plan) => plan.name === "pro")[0];
    this.business = this.plans.filter((plan) => plan.name === "business")[0];
    this.enterprise = this.plans.filter(
      (plan) => plan.name === "enterprise"
    )[0];
  }
}
</script>

<style scoped>
.border {
  border: 1px solid lightgray;
  border-radius: 0.75rem;
}

.scale-test {
  transform: scale(0.9);
}
</style>
