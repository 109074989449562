<template>
  <!-- <b-col :class="containerClass"> -->
  <b-card class="basic-card" no-body>
    <b-row>
      <b-col class="col-7">
        <b-card-text class="subscription-header text-center">Basic</b-card-text>
      </b-col>
    </b-row>

    <!-- <b-row v-if="modalType === 'billing'" style="margin-bottom:-1.5rem">
      <b-col>
        <span class="subscription-price">Free</span>
      </b-col>
    </b-row> -->

    <b-row v-if="modalType === 'billing'" style="margin-bottom: -1.5rem">
      <b-col>
        <span class="dollar">$</span>
        <span class="subscription-price">{{ basic.price }}</span>
        <span class="subscription-price-text"
          >/ {{ $filters.capitalizeFirst(basic.interval as string) }}</span
        >
      </b-col>
    </b-row>

    <b-row class="pl-4 pt-4" v-if="modalType !== 'billing'">
      <b-col>
        <b-card-text class="plan-description"
          >For brands looking to make an impact</b-card-text
        >
      </b-col>
    </b-row>

    <b-row class="pl-2 py-4">
      <b-col class="">
        <b-list-group>
          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3"
              >Basic Company Categories*</span
            ></b-list-group-item
          >
          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3">1 Product Experience</span></b-list-group-item
          >
          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3">Product Real Code</span></b-list-group-item
          >

          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3">3 Sections per Product</span></b-list-group-item
          >

          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3"
              >External Links for CTAs</span
            ></b-list-group-item
          >

          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3">Cloud Hosting</span></b-list-group-item
          >

          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3"
              >Full Real App Integration &amp; Updates</span
            ></b-list-group-item
          >

          <b-list-group-item class="pt-0"
            ><icon icon="check2" scale="2"></icon
            ><span class="pl-3">Basic Analytics</span></b-list-group-item
          >
        </b-list-group>
      </b-col>
    </b-row>

    <b-row
      class="px-3 pb-3 align-items-end align-self-center row-bottom"
      v-if="showSubmit"
    >
      <b-col>
        <payment-choices :subscription-type="basic"> </payment-choices>
      </b-col>
    </b-row>
  </b-card>
  <!-- </b-col> -->

  <!-- <b-card border-variant="0" class="plan-card">
    <b-card-header header-bg-variant="transparent" header-border-variant="0" class="plan-header text-center">Basic</b-card-header>
    <b-card-body
      ><b-card-text
        >Basic plan here. CSR sections. No premium (pro) content.
        {{ planDetails.productNumber }} product, {{ planDetails.productSections }} sections.
        $0/month</b-card-text
      ></b-card-body
    >
  </b-card> -->
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import Plan from "../../../models/Plan";
import PaymentChoices from "../PaymentChoices.vue";

@Component({
  components: {
    PaymentChoices,
  },
})
export default class BasicPlan extends Vue {
  @Prop()
  planDetails!: Plan;

  @Prop()
  modalType!: string;

  @Prop()
  showSubmit!: boolean;

  get plans(): Plan[] {
    return this.$store.getters.plans;
  }

  basic: Partial<Plan> = {};

  async mounted() {
    this.basic = this.plans.filter((plan: Plan) => plan.name === "basic")[0];
  }
}
</script>

<style scoped>
.plan-card {
  border-radius: 0.75rem;
}
.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}

.card {
  border: 2px solid #898989;
  border-radius: 0.75rem;
}

/* .signature-card {
  height: 60%;
} */
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .basic-card {
    height: 100%;
  }
}
/* Portrait tablets and small desktops */
@media (max-width: 991px) {
  .basic-card {
    height: 100%;
  }
}
/* 
.icon.bi {
  color: #3679e8;
} */

.card-body {
  padding-top: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.list-group {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.row-bottom {
  flex: 1 0 0;
}

.subscription-price {
  font-size: 64px;
  font-family: "proximanova-medium", sans-serif;
  padding-right: 0px;
  padding-left: 3.5rem;
}

.plan-description {
  font-size: 16px;
  font-family: "proximanova-medium", sans-serif;
}

.dollar {
  font-size: 2rem;
  vertical-align: top;
  position: absolute;
  top: 1rem;
  padding-left: 2rem;
}

.subscription-price-text {
  font-size: 20px;
  font-family: "proximanova-regular", sans-serif;
}

.subscription-header {
  font-family: "proximanova-regular", sans-serif;
  font-size: 22px;
  background-color: #d5f9de;
  color: #074f57;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
</style>
