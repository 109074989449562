/**
 * Vuex store for products
 */
import { Product } from "@authentic-labs/vue-component-library";
import { Commit } from "vuex";
import { fetchProduct } from "../../services/ProductService";
import { Cta } from "@authentic-labs/survey-builder";
import Asset from "@/models/Asset";

type ProductState = {
  product: Product;
  products: Product[];
  validForm: boolean;
  imageFile: File;
  productStatus: string;
  filter: string;
  productCta: Cta;
};

const initialState = {
  product: {},
  imageFile: null,
  productStatus: "",
  filter: "Alphabetic",
};

export default {
  state: initialState,

  getters: {
    product(state: ProductState) {
      return state.product;
    },
    products(state: ProductState) {
      return state.products;
    },
    validForm(state: ProductState) {
      return state.validForm;
    },
    imageFile(state: ProductState) {
      return state.imageFile;
    },
    productStatus(state: ProductState) {
      return state.productStatus;
    },
    filter(state: ProductState) {
      return state.filter;
    },
    //getProductState: state => state.productStatus
  },

  mutations: {
    setProduct(state: ProductState, product: Product) {
      state.product = product;
    },
    setProductCta(state: ProductState, productCta: Cta) {
      state.product.ctas = [];
      state.product.ctas?.push(productCta);
    },
    setValidForm(state: ProductState, validForm: boolean) {
      state.validForm = validForm;
    },
    setImageFile(state: ProductState, imageFile: File) {
      state.imageFile = imageFile;
    },
    setProductStatus(state: ProductState, productStatus: string) {
      state.productStatus = productStatus;
    },
    resetProducts(state: ProductState) {
      Object.assign(state, initialState);
    },
    setFilter(state: ProductState, filter: string) {
      state.filter = filter;
    },
    setProducts(state: ProductState, products: Product[]) {
      state.products = products;
    },
    setProductAsset(state: ProductState, productAsset: Asset) {
      state.product.assets = [];
      state.product.assets?.push(productAsset);
    },
    clearProductAsset(state: ProductState) {
      state.product.assets = [];
    },
  },

  actions: {
    async getProduct(
      { commit }: { commit: Commit },
      payLoad: { companyId: number; productId: number }
    ) {
      try {
        const product = await fetchProduct(
          payLoad.companyId,
          payLoad.productId
        );
        commit("setProduct", product);
      } catch (error) {
        console.error("Error retrieving company: ", error);
      }
    },
  },
};
