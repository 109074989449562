/**
 * Vuex store for initialy loading analytics data
 */
// import { Cta } from "@authentic-labs/survey-builder"
import { getAnalyticsData } from "@/services/AnalyticsService";
import { Commit } from "vuex";

interface AnalyticState {
    analyticsData: object;
}

const initialState = {
};

export default {
    state: initialState,

    getters: {
        analyticsData(state: AnalyticState) {
        return state.analyticsData;
        },
    },

    actions: {
        async getAnalyticsData({ commit }: { commit: Commit }, { companyId, dateRange }: { companyId: number, dateRange: object }) {
            const data = await getAnalyticsData(companyId, dateRange);
            commit("setAnalyticsData", Object.assign(data, { dateRange }));
        }
    },

    mutations: {
        setAnalyticsData(state: AnalyticState, data: object) {
            state.analyticsData = data;
        },
        resetAnalyticsData(state: AnalyticState) {
            state.analyticsData = {};
        }
    },
}