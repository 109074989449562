<template>
  <authentic-modal
    ref="companyDetailsModal"
    id="company-details-modal"
    :on-submit="submit"
    size="lg"
    :title="modalTitle"
  >
    <company-details-form :adminType="'admin'" :isSubmitting="isSubmitting">
      <template v-slot:company-industy> </template>
      <template v-slot:name-input></template>
    </company-details-form>
    <template v-slot:modal-footer>
      <b-button-group class="pr-4">
        <b-button
          pill
          variant="outline-secondary"
          size="sm"
          class="ml-2"
          @click.prevent="cancel"
          >Cancel</b-button
        >
        <b-button
          pill
          variant="primary"
          size="sm"
          class="ml-2"
          @click.prevent="submit"
          >Save</b-button
        >
      </b-button-group>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
import { Company } from "@authentic-labs/vue-component-library";
import {
  fetchCompanyAdmin,
  updateCompanyAdmin,
} from "../../services/AdminService";
import AuthenticModal from "../shared/AuthenticModal.vue";
import CompanyDetailsForm from "./CompanyDetailsForm.vue";
import User from "../../models/User";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { computed, inject, markRaw, onMounted, ref, onBeforeMount } from "vue";
import { useToast } from "vue-toastification";

const emitter: any = inject("emitter");
const $store = useStore();
const toast = useToast();

const props = defineProps({
  isValid: Boolean,
});

const user = computed(() => {
  return $store.getters.authUser;
});
const company = computed(() => {
  return $store.getters.company;
});
const modalTitle = "Company details";
const logoFile: File | null = null;

const companyDetailsModal = ref<AuthenticModal>();
const isSubmitting = ref(false);

onBeforeMount(() => {
  emitter.on("company-details", async () => {
    //TODO moving to admin service for this info
    //await this.$store.dispatch("getCompany", this.user.companyId!);
    const companyResponse = await fetchCompanyAdmin(company.value.companyId);
    $store.dispatch("setCompanyStore", companyResponse);
    companyDetailsModal.value?.show();
  });
  emitter.on("submitCompanyDetails", (isValid: boolean) => {
    checkDetailsForm(isValid);
  });
});



function checkDetailsForm(isValid: boolean){
  if(isValid){
    updateCompanyDetails();
  } else {
  isSubmitting.value = false;
  }
}

function submit(){
  isSubmitting.value = true
}

async function updateCompanyDetails() {
  const companyUpdate = companyDetailsModal.value?.$store.getters.company;
  try {
    await updateCompanyAdmin(company.value.companyId, companyUpdate);
    companyDetailsModal.value?.hide();
    toast.success("Company details for " +
        companyUpdate.companyName +
        " have been successfully update!")
  } catch {
    toast.error("Company update failed, please try again");
  }
  finally {
    isSubmitting.value = false;
  }
}

function showCompanyDetails() {
  companyDetailsModal.value?.show();
}

async function cancel() {
  if ($store.getters.companyLogoFile.filesId) {
    company.value.logoFile = $store.getters.companyLogoFile;
  }
  companyDetailsModal.value?.hide();
}
</script>

<style scoped></style>
