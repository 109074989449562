<template>
  <div style="background: white" >
    <BNavbar
      toggleable="lg"
      type="dark"
      variant="primary"
      class="navbar-custom"
    >
      <BContainer class="container-lg">
        <BNavbarBrand href="#">
          <router-link
            :to="{ name: 'signature', query: { companyId: user.companyId } }"
            ><img :src="getReal" alt="Real Logo" height="30" @click="refresh()"
          /></router-link>
        </BNavbarBrand>
        <b-navbar-toggle
          target="nav-collapse"
          v-b-toggle.sidebar-right
          v-if="isLoggedIn"
        >
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto" v-if="user.name">
            <b-nav-item-dropdown
              v-if="isLoggedIn"
              toggle-class="nav-link-custom"
              right
            >
              <template v-slot:button-content>
                <b-img alt="Avatar" :src="avatarUrl" />
                <span class="icon-spacing">{{ user.name }}</span>
                <icon icon="chevron-down" class="icon-spacing"></icon>
              </template>
              <b-dropdown-item
                v-if="user.role === 'Superadmin'" link-class="user-dropdown"
                @click="companyAdmin"
              >
                <!-- <iconstack class="icon">
                  <icon stacked icon="person" class="icon"></icon>
                  <icon stacked icon="gear" class="icon"></icon>
                </iconstack> -->
                <icon icon="gear" class="icon"></icon>
                Super admin
              </b-dropdown-item>
              <b-dropdown-item @click="editProfile" link-class="d-flex" >
                <iconstack class="icon icon-stack">
                  <icon stacked icon="person" class="icon"></icon>
                  <icon stacked icon="circle" class="icon"></icon>
                </iconstack>
                <!-- <icon icon="person" class="icon"></icon> -->
                Edit profile
              </b-dropdown-item>
              <b-dropdown-item
                v-if="user.role === 'Admin' || user.role === 'Superadmin'" link-class="user-dropdown"
                @click="billingSummary"
              >
                <iconstack class="icon icon-billing">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="Group_329"
                      data-name="Group 329"
                      transform="translate(-25.906 -18.412)"
                    >
                      <g id="Group_331" data-name="Group 331">
                        <path
                          id="Path_3340"
                          data-name="Path 3340"
                          d="M31.574,48.312a12,12,0,0,1,12-20.557"
                          transform="translate(0 -4.786)"
                          fill="none"
                          stroke="#999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_3341"
                          data-name="Path 3341"
                          d="M59.8,36.923V32.89h3.978"
                          transform="translate(110.88 37.487) rotate(163)"
                          fill="none"
                          stroke="#999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                      <g
                        id="Group_332"
                        data-name="Group 332"
                        transform="translate(78.986 68.187) rotate(-180)"
                      >
                        <path
                          id="Path_3340-2"
                          data-name="Path 3340"
                          d="M31.574,48.312a12,12,0,0,1,12-20.557"
                          transform="translate(0 -4.786)"
                          fill="none"
                          stroke="#999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_3341-2"
                          data-name="Path 3341"
                          d="M59.8,36.923V32.89h3.978"
                          transform="translate(110.88 37.487) rotate(163)"
                          fill="none"
                          stroke="#999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </iconstack>
                Billing
              </b-dropdown-item>

              <b-dropdown-item
                v-if="user.role === 'Superadmin' || user.role === 'Admin'" link-class="user-dropdown"
                @click="companyDetails"
              >
                <icon icon="briefcase" class="icon"></icon>
                Company details
              </b-dropdown-item>
              <b-dropdown-item
                v-if="user.role === 'Superadmin' || user.role === 'Admin'" link-class="user-dropdown"
                @click="userManagement"
              >
                <icon icon="list-task" class="icon"></icon>
                User management
              </b-dropdown-item>
              <b-dropdown-item @click="logout">
                <icon icon="x-circle" class="icon"></icon>
                Log out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </BContainer>
    </BNavbar>

    <b-sidebar
      id="sidebar-right"
      title=""
      no-header
      right
      shadow
      backdrop
      class="d-lg-none"
    >
      <template #default>
        <div class="p-0">
          <div class="container-lg custom-sidebar">
            <b-navbar-brand class="d-md-block d-lg-none brand-mobile-sidebar">
              <router-link
                :to="{
                  name: 'signature',
                  query: { companyId: user.companyId },
                }"
              >
                <img
                  :src="getReal"
                  alt="Real Logo"
                  height="30"
                  @click="refresh()"
                />
              </router-link>
            </b-navbar-brand>

            <b-navbar-toggle
              class="close-sidebar"
              target="nav-collapse"
              v-b-toggle.sidebar-right
            ></b-navbar-toggle>
          </div>

          <b-card no-body class="links">
            <b-link
              class="p-2 side-menu-links"
              v-if="user.role === 'Superadmin'"
              @click="companyAdmin"
            >
              <h4 class="h4-b text-center pr-3">Super Admin</h4></b-link
            >
            <b-link class="p-2 side-menu-links" @click="editProfile">
              <h4 class="h4-b text-center pr-3">Edit profile</h4></b-link
            >
            <b-link
              class="p-2 side-menu-links"
              v-if="user.role === 'Admin' || 'Superadmin'"
              @click="billingSummary"
              ><h4 class="h4-b text-center pr-3">Billing</h4></b-link
            >
            <b-link
              class="p-2 side-menu-links"
              v-if="user.role === 'Superadmin' || user.role === 'Admin'"
              @click="companyDetails"
            >
              <h4 class="h4-b text-center pr-3">Company detail</h4></b-link
            >
            <b-link
              class="p-2 side-menu-links bottom-border-off"
              v-if="user.role === 'Superadmin' || user.role === 'Admin'"
              @click="userManagement"
            >
              <h4 class="h4-b text-center pr-3">User management</h4></b-link
            >
            <b-link
              class="p-2 side-menu-links bottom-border-off"
              @click="logout"
            >
              <h4 class="h4-b text-center pr-3">Log out</h4></b-link
            >
          </b-card>
        </div>
      </template>
    </b-sidebar>
    <CompanyDetails />
    <CompanyUsers v-if="user.name" />
    <UserProfile />
    <CompanyBilling />
    <RealSubscriptions /> <!-- TODO add this back fix -->
    <PaymentModal />
    <BillingModal />
  </div>
</template>

<script lang="ts">
import UserProfile from "../components/auth/UserProfile.vue";
import User from "../models/User";
import { Company } from "@authentic-labs/vue-component-library";
import CompanyDetails from "../components/accounting/CompanyDetails.vue";
import CompanyUsers from "../components/accounting/CompanyUsers.vue";
import CompanyBilling from "../components/accounting/CompanyBilling.vue";
import { logout } from "../services/AuthService";
import PaymentModal from "../components/accounting/PaymentModal.vue";
import BillingModal from "../components/accounting/BillingModal.vue";
import RealSubscriptions from "../components/shared/RealSubscriptions.vue";

import { Component, Vue } from "vue-facing-decorator";
import { useStore } from "vuex";

import defaultAvi from "@/assets/images/avatars/sm_bot1.jpg"

@Component({
  components: {
    UserProfile,
    CompanyDetails,
    CompanyUsers,
    CompanyBilling,
    RealSubscriptions,
    PaymentModal,
    BillingModal,
  },
})
export default class NavigationBar extends Vue {
  // @Ref()
  // profile!: UserProfile;

  get isLoggedIn(): boolean {
    return this.$store.getters.isLoggedIn;
  }

  get user(): User {
    return this.$store.getters.authUser;
  }
  get company(): Company {
    return this.$store.getters.company;
  }

  get subscription() {
    return this.$store.getters.subscription;
  }

  get avatarUrl(): string {
    if(this.user.avatar === null) {
      return new URL("/src/assets/images/avatars/sm_bot1.jpg", import.meta.url).href
    }
    return new URL(`/src/assets/images/avatars/sm_${this.user.avatar}`, import.meta.url).href
  }

  get getReal(): string {
    return new URL(`@/assets/images/app/RealLogo.svg`, import.meta.url).href
  }

  // store = useStore();

  created() {
    const store = useStore();

    const now = new Date();
    const scheduledTime = new Date(now);
    // scheduledTime.setHours(5, 0, 0); //doing every 2 hours instead of 5am for now

    
    // setTimeout(() => {
    //   if(this.$route.name === "analytics") {
    //     this.emitter.emit("refreshAnalytics");
    //   } else {
    //     this.getAnalytics();
    //   }
    //   this.rescheduleRefresh();
    // }, 2*60*60*1000);
    
  }

  mounted() {
    this.getAnalytics();
  }

  // rescheduleRefresh() {
  //   const delay = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  //   setTimeout(() => {
  //     if(this.$route.name === "analytics") {
  //       this.emitter.emit("refreshAnalytics");
  //     } else {
  //       this.getAnalytics();
  //     }
  //     this.rescheduleRefresh();
  //   }, delay);
  // }

  async getAnalytics() {
    try {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const startDate = thirtyDaysAgo.toISOString().split('T')[0];
      const endDate = new Date().toISOString().split('T')[0];
      this.$store.commit("resetAnalyticsData", {});
      await this.$store.dispatch("getAnalyticsData", {companyId: this.company.companyId, dateRange: {startDate: startDate, endDate: endDate}});
    } catch (error) {
      console.log(error, " Error retrieving company analytics data");
    }
  }

  beforeDestroy() {
    // console.log('navbar destroyed')
  }

  editProfile() {
    this.emitter.emit("user-profile");
  }

  companyAdmin() {
    this.$router.push({ name: "companyList" });
  }

  companyDetails() {
    this.emitter.emit("company-details");
  }

  userManagement() {
    this.emitter.emit("user-management");
  }

  async billingSummary() {
    if (this.user.role === "Admin") {
      if (this.company.demo) {
        return;
      }
      if (
        this.company.type !== "free" &&
        this.company.type !== null &&
        !this.company.demo &&
        this.company.status === "Active" &&
        this.subscription.isActive
      ) {
        this.emitter.emit("billing-modal"); 
      } else {
        this.emitter.emit("subscription-modal");
      }
    } else if (this.user.role === "Superadmin") {
      this.emitter.emit("subscription-modal"); 
    }
  }

  async refresh() {
    this.$store.commit("updatePreview", "company");
    if(this.user.role === "Superadmin" && this.company.companyId !== this.user.companyId){
      if (!this.$route.path.includes("product")) {
        this.$store.commit("resetCompany");
        await this.$store.dispatch("getCompany", this.user.companyId);
        this.$store.dispatch("getSubscriptionStatus", { user: this.user });
        this.getAnalytics();
      }
    } else {
      this.getAnalytics();
    }
  }

  async logout() {
    try {
      await logout();
      this.$store.commit("login", false);
      this.$store.commit("authUser", {});
      this.$store.commit("resetCompany", {});
      this.$store.commit("resetProducts", {});
      this.$store.commit("setSubscription", {});
      this.$store.commit("setSessionStart", "");
      this.$store.commit("setGs1Settings", {});
      this.$router.push({ name: "login" });
    } catch (error) {
      console.log("Error: ", error);
    }
  }
}
</script>

<style scoped lang="scss">
// @import "@/assets/scss/main.scss";
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1450px;
  }
}

.navbar-custom{
  height:50px;
  padding: .5rem 1rem;
}
:deep .dropdown-item:hover,
.dropdown-item:focus {
  font-family: "proximanova-bold", sans-serif;
  /* font-weight: bold; */
  color: #898989;
  text-decoration: none;
  background-color: #e9ecef;
}

:deep .dropdown-item {
  font-family: "proximanova-regular", sans-serif;
  color: #898989;
}

.ani-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  transform: translateY(-3em);
  z-index: -1;
  transition: all 0.3s ease 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

:deep .dropdown:focus .ani-menu,
.dropdown:focus-within .ani-menu,
.dropdown:hover .ani-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0);
  transition-delay: 0s, 0s, 0.3s;
}


.icon-spacing {
  margin-left: 0.5rem;
}
.icon {
  color: #898989;
  margin-right: 0.5rem;
}

:deep(.user-dropdown) {
  display: flex;
  align-items: center;
}

.icon-billing {
  margin-bottom: 0.175rem;
}

.icon-stack {
    position: relative;
    width:20px;
    display: flex;
    margin-top:2px;
}
.icon-stack .icon {
    position: absolute; 
    width:20px
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  line-height: 60px;
  background-color: #f9f9f9;
  display: flex;
}

.brand-mobile-sidebar {
  padding-top: 0.85rem;
  padding-left: 2rem;
}

.close-sidebar {
  position: absolute;
  right: 3rem;
  top: 0.6rem;
}

.brand-mobile {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 1.3rem;
}

.mobile-toggle {
  position: absolute;
  right: 1rem;
  top: 1.72rem;
  padding-bottom: 10px;
}

.side-menu-links {
  height: 45px;
  text-decoration: none;
}

.card {
  border: none;
}

.links {
  padding-top: 5rem;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    margin-right: 1rem;
    font-size: 1.125rem;
    line-height: inherit;
    white-space: nowrap;
}

.custom-sidebar {
  background-color: #3679e8;
  height: 50px;
}

:deep .b-sidebar {
  border-bottom-left-radius: 12px !important;
  min-width: 100%;
  z-index: 1;
  top: -3px;
  background-color: #ffffff !important;
}

:deep .b-sidebar-header {
  background-color: #3679e8;
  height: 54px;
  align-items: flex-end !important;
}

:deep .b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
  position: absolute;
  right: 20px;
  color: black !important;
  opacity: 1;
}

:deep .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

:deep .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

@media all and (min-width: 992px) {
  :deep .navbar .nav-item .dropdown-menu {
    display: none;
  }
  :deep .navbar .nav-item:hover .nav-link {
    color: #fff;
  }
  :deep .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  :deep .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
:deep .dropdown-toggle::after {
  display: none;
}
</style>
