/**
 * Service for company API calls
 */
import { apiClient } from "./Api";
import { Company } from "@authentic-labs/vue-component-library";
import Industry from "../models/Industry";
import User from "../models/User";
import ContentSection from "../models/ContentSection";

export async function fetchIndustries() {
  const { data: industries } = await apiClient.get<Industry[]>(
    "/api/industries"
  );
  return industries;
}
