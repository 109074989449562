/**
 * Service for company API calls
 */
import { apiClient } from "./Api";
import { Company } from "@authentic-labs/vue-component-library";
import User from "../models/User";
import ContentSection from "../models/ContentSection";

export async function fetchUsers(companyId: number, payLoad: object) {
  const { data: usersRepsonse } = await apiClient.get(
    `/api/companies/${companyId}/users`,
    {
      params: payLoad,
    }
  );
  return {
    resultsReturned: usersRepsonse.users.total,
    currentPage: usersRepsonse.users.current_page,
    users: usersRepsonse.users.data,
    userCount: usersRepsonse.userCount,
  };
}

export async function fetchCompany(companyId: number) {
  const { data: company } = await apiClient.get(`/api/companies/${companyId}`);
  return {
    company: new Company(company),
    customSections: company.customSections,
    contentSections: company.contentSection,
    paidSections: company.paidSections,
    // paidSections: company.contentSection.filter(
    //   (section: ContentSection) => section.pivot!.paid
    // ),
    stats: company.stats,
  };
}

export async function updateCompany(companyId: number, payLoad: object) {
  const { data: company } = await apiClient.put(
    `/api/companies/${companyId}`,
    payLoad
  );
  return company;
}

export async function publishCompany(company: Company, payLoad: object) {
  const { data: dates } = await apiClient.put(company.publishUrl, payLoad);
  return dates;
}

