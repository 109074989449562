<template>
  <!-- TODO add type and then video element -->
  <b-img
    :src="media.fileUrl"
    width="140"
    :class="selected ? 'selected' : 'unselected'"
    @click="selectedAsset"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";

@Component
export default class MediaAsset extends Vue {
  @Prop()
  media: any;

  selected = false;

  selectedAsset() {
    this.selected = !this.selected;
  }
}
</script>

<style scoped>
.selected {
  border: 2px solid #27a8e0;
}
.unselected {
  border: 2px dashed #27a8e0;
}

.gallery-panel {
  width: 144px;
  height: 144px;
  /* border: 2px dashed #27A8E0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-panel img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}
</style>
