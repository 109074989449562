<template>
  <!-- TODO starting to integrate our asset gallery, for now only doing products so we have the fromSummary flag, if we add to company need to refactor whole component -->
  <div :class="containerClass">
    <b-img
      id="product-image"
      :src="imagePreview"
      class="img-fluid d-block"
      :class="imageClass"
    >
    </b-img>

    <div v-if="upLoadButton && !inline && fromSummary">
      <BButton
        size="sm"
        variant="primary"
        class="mb-2 p-0"
        :class="buttonClass"
        @click="getGallery('image')"
      >
      <icon icon="plus" scale="1.75"></icon>
    </BButton>
      <b-form-file
        id="image"
        form="form-details"
        ref="listImage"
        v-model="imageFile"
        accept="image/*"
        @change="previewImage"
        style="display: none"
      ></b-form-file>
    </div>

    <div v-if="upLoadButton && !inline && !fromSummary">
      <BButton
        size="sm"
        variant="primary"
        class="mb-2 p-0"
        :class="buttonClass"
        @click="($refs.listImage as any).$el.querySelector('input[type=file]').click()"
      >
        <icon icon="plus" scale="1.5"></icon>

      </BButton>
      <b-form-file
        id="image"
        form="form-details"
        ref="listImage"
        v-model="imageFile"
        accept="image/*"
        @change="previewImage"
        style="display: none"
      ></b-form-file>
    </div>

    <div v-if="upLoadButton && inline">
      <b-button
        size="sm"
        variant="primary"
        class="mb-2 p-0"
        :class="buttonClass"
        @click="($refs.listImage as any).$el.querySelector('input[type=file]').click()"
      >
      <icon icon="plus" scale="1.75"></icon>
      </b-button>
      <b-form-file
        id="image"
        form="form-details"
        ref="listImage"
        v-model="imageFile"
        accept="image/*"
        @change="previewImage"
        style="display: none"
      ></b-form-file>
    </div>

    <!-- <AssetGalleryModal v-if="fromSummary" :type="'image'" /> -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AssetGalleryModal from "../shared/assetlibrary/AssetGalleryModal.vue";
import { BButton, BFormFile, BImg } from "bootstrap-vue";

@Component({
  components: {
    AssetGalleryModal,
    BButton,
    BFormFile,
    BImg
  },
})
export default class ImageSelector extends Vue {
  @Prop()
  imageSrc!: string;

  @Prop()
  size!: string;

  @Prop()
  buttonLocation!: string;

  @Prop()
  upLoad!: boolean;

  @Prop()
  inline!: boolean;

  @Prop()
  fromSummary!: boolean;

  get imagePreview() {
    if (this.selectedImage) {
      return this.selectedImage;
    } else {
      return this.imageSrc;
    }
  }

  get containerClass() {
    return this.size ? "image-container-" + this.size : "image-container-lg";
  }
  get imageClass() {
    return this.size ? "image-" + this.size : "image-lg";
  }
  get buttonClass() {
    return this.buttonLocation
      ? "btn-circle-" + this.size + "-" + this.buttonLocation
      : "btn-circle-lg-center";
  }

  imageFile: File | null = null;
  upLoadButton = false;
  iconScale = "1.75";
  selectedImage = "";

  mounted() {
    this.setParams();
  }

  setParams() {
    this.upLoadButton = this.upLoad;
    if (this.size === "sm") {
      this.iconScale = "1.5";
    }
  }

  getGallery(type: string) {
    this.emitter.emit("media-select", type);
  }

  previewImage(event: { target: any }) {
    const inFile = event.target;
    if (inFile.files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target?.result as string;
        this.$emit("change", this.imagePreview);
      };
      const test = reader.readAsDataURL(inFile.files[0]);
    }
  }
}
</script>

<style scoped>
/* TODO need the image selector size to be more dynamic letting container determine width
   TODO make sure this has no adverse effects in other places away from superadmin/admin screens */
.bi-plus::before {
  transform: scale(1.6);
  /* font-weight: bold !important; */
}
.image-lg {
  border: 0;
  border-radius: 0.5rem;
  /* width: 136px; */
  background-color: #f9f9f9;
  margin: auto;
}

.image-container-lg {
  display: flex;
  position: relative;
  height: 136px;
  width: 136px;
  background-color: #898989;
  border-radius: 0.5rem;
  align-items: center;
}

.image-sm {
  border: 0;
  border-radius: 0.5rem;
  /* width: 136px; */
  background-color: #f9f9f9;
  margin: auto;
}

.image-container-sm {
  display: flex;
  position: relative;
  width: 72px;
  height: 72px;
  background-color: #898989;
  border-radius: 0.5rem;
  align-items: center;
}

.image-xs {
  border: 0;
  /* border-radius: 0.5rem; */
  background-color: #f9f9f9;
  height: 32px;
  width: auto;
  margin: auto;
}

.image-container-xs {
  display: flex;
  position: relative;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  align-items: center;
}

.btn-circle-lg-center {
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-lg-ul {
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 0;
  position: absolute;
  top: -7%;
  left: -7%;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
}

.btn-circle-sm-center {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-xs-right {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-sm-ul {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: -7%;
  left: -7%;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
}
</style>
